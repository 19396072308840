import { SSG_STATES_START, SSG_STATES_SUCCESS, SSG_STATES_FAIL } from '../types/types';
import { getStates } from '../../../services/ssg/ssg';

export const statesStart = () => {
  return {
    type: SSG_STATES_START,
    payload: { error: null }
  };
};

export const statesSuccess = states => {
  return {
    type: SSG_STATES_SUCCESS,
    payload: states
  };
};

export const statesFail = error => {
  return {
    type: SSG_STATES_FAIL,
    payload: { error }
  };
};

export const states = (countryId, token) => {
  return dispatch => {
    dispatch(statesStart());

    getStates(countryId, token)
      .then(response => {
        dispatch(statesSuccess(response.data));
      })
      .catch(err => {
        try {
          dispatch(statesFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(statesFail('Please try again later..'));
        }
      });
  };
};
