import axios from 'axios';
import Swal from 'sweetalert2';
import siteConfig from '../config/site.config';
import * as actions from '../actions/customer/index';
import { createBrowserHistory } from 'history';
import { getNewToken } from '../services/customer/customer';
import store from '../common/redux/store';
const history = createBrowserHistory();

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const axiosInstance = axios.create({
  baseURL: siteConfig.apiUrl_Customer,
  _retry: false
  //  headers: { authorization: localStorage.getItem('token') }
});

axiosInstance.interceptors.response.use(
  function(response) {
    return response;
  },
  error => {
    const originalReq = error.config;

    if (error.response.status === 401 && !originalReq._retry) {
      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalReq.headers['authorization'] = token;
            return axios(originalReq);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalReq._retry = true;
      isRefreshing = true;

      const refToken = localStorage.getItem('customerRefreshToken');
      const rememberMe = localStorage.getItem('customerRememberMe');
      const refreshToken = {
        refresh_token: refToken
      };

      return new Promise((resolve, reject) => {
        getNewToken(refreshToken)
          .then(response => {
            const customerToken = response.data.token;
            const customerRefreshToken = response.data.refresh_token;
            const customerUserId = localStorage.getItem('customerUserId');
            const customerUserType = 'customer';
            const avatar = null;

            if (rememberMe === true) {
              localStorage.setItem('customerToken', customerToken);
            }

            localStorage.setItem('customerRefreshToken', customerRefreshToken);
            localStorage.setItem('customerUserId', customerUserId);
            localStorage.setItem('customerUserType', 'customer');

            store.dispatch(
              actions.authSuccess(customerToken, customerRefreshToken, customerUserId, customerUserType, avatar)
            );

            originalReq.headers['authorization'] = customerToken;

            processQueue(null, customerToken);

            resolve(axios(originalReq));
          })
          .catch(err => {
            if (err.response.status === 401 || err.response.status === 400) {
              const res = Swal.fire({
                title: 'Session Expired!',
                text: 'Your session has expired. Please login again.',
                icon: 'warning',
                confirmButtonText: 'Log In',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
              }).then(result => {
                if (result.isConfirmed) {
                  actions.logout();
                  history.go('/');
                }
              });
            }
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
