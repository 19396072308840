import {
  CUSTOMER_REPORT_FILTER_START,
  CUSTOMER_REPORT_FILTER_SUCCESS,
  CUSTOMER_REPORT_FILTER_FAIL
} from '../types/types';
import { geReportFilters } from '../../../services/customer/customer';

export const reportFilterStart = () => {
  return {
    type: CUSTOMER_REPORT_FILTER_START,
    payload: { error: null }
  };
};

export const reportFilterSuccess = categories => {
  return {
    type: CUSTOMER_REPORT_FILTER_SUCCESS,
    payload: categories
  };
};

export const ReportFilterFail = error => {
  return {
    type: CUSTOMER_REPORT_FILTER_FAIL,
    payload: { error }
  };
};

export const reportFilters = token => {
  return dispatch => {
    dispatch(reportFilterStart());

    geReportFilters(token)
      .then(response => {
        dispatch(reportFilterSuccess(response.data));
      })
      .catch(err => {
        try {
          dispatch(ReportFilterFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(ReportFilterFail('Please try again later..'));
        }
      });
  };
};
