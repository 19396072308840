import {
  CUSTOMER_CATEGORY_FIELDS_START,
  CUSTOMER_CATEGORY_FIELDS_SUCCESS,
  CUSTOMER_CATEGORY_FIELDS_FAIL
} from '../types/types';
import { getCategoryFields } from '../../../services/customer/customer';

export const categoryFieldStart = () => {
  return {
    type: CUSTOMER_CATEGORY_FIELDS_START,
    payload: { error: null }
  };
};

export const categoryFieldSuccess = status => {
  return {
    type: CUSTOMER_CATEGORY_FIELDS_SUCCESS,
    payload: status
  };
};

export const categoryFieldFail = error => {
  return {
    type: CUSTOMER_CATEGORY_FIELDS_FAIL,
    payload: { error }
  };
};

export const categoryFields = token => {
  return dispatch => {
    dispatch(categoryFieldStart());

    getCategoryFields(token)
      .then(response => {
        dispatch(categoryFieldSuccess(response.data));
      })
      .catch(err => {
        try {
          dispatch(categoryFieldFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(categoryFieldFail('Please try again later..'));
        }
      });
  };
};
