import API from '../../helpers/api';

//Auth
export const signIn = authData => {
  return API.post('auth/signin', authData);
};

export const forgotPassword = email => {
  return API.post('/auth/forgot-password', email);
};

export const resetPassword = resetDetails => {
  return API.post('/auth/reset-password', resetDetails);
};

export const getNewToken = refreshToken => {
  return API.post('/auth/token', refreshToken);
};

//Users
export const getUsers = token => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('users', config);
};

export const creatNewUser = (userInfor, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.post('users', userInfor, config);
};

export const updateUser = (userid, userInfor, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.put('users/' + userid, userInfor, config);
};

export const deleteUser = (userid, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.delete('users/' + userid, config);
};

//User profile
export const getUserProfile = token => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token, 'x-ssg-tenant-id': 'cenango' }
  };

  return API.get('me', config);
};

export const updateProfile = (userInfor, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token, 'x-ssg-tenant-id': 'cenango' }
  };

  return API.put('me', userInfor, config);
};

//Groups
export const getUserGroups = token => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('groups', config);
};

export const getUserGroup = (groupid, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('groups/' + groupid, config);
};

export const createNewGroup = (groupInfor, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.post('groups', groupInfor, config);
};

export const updateGroup = (groupId, groupInfor, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.put('groups/' + groupId, groupInfor, config);
};

export const deleteGroup = (groupId, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.delete('groups/' + groupId, config);
};

//Roles
export const getRoles = token => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('users/roles', config);
};

//User Status
export const getUserStatus = token => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('status/user', config);
};

//Plans
export const getPlans = token => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('plans', config);
};

export const getPlan = (planId, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('plans/' + planId, config);
};

export const updatePlans = (planId, planInfor, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.put('plans/' + planId, planInfor, config);
};

export const getPlansTenants = (planId, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get(`plans/ ${planId}/tenants`, config);
};

export const getPlansSettings = token => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('plans/settings', config);
};

//Tenants

export const getTenants = token => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('tenants', config);
};

export const createNewTenant = async (tenantInfor, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return await API.post('tenants', tenantInfor, config);
};

export const getTenant = (tenantId, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('tenants/' + tenantId, config);
};

export const updateTenant = (tenantId, tenantInfor, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.put('tenants/' + tenantId, tenantInfor, config);
};

export const deleteTenant = (tenantId, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.delete('tenants/' + tenantId, config);
};

export const getTenantSuperAdmins = (tenantId, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('tenants/' + tenantId + '/users', config);
};

export const createNewTenantSuperAdmin = (tenantId, adminInfor, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.post('tenants/' + tenantId + '/users', adminInfor, config);
};

export const updateTenantSuperAdmin = (tenantId, userId, adminInfor, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.put('tenants/' + tenantId + '/users/' + userId, adminInfor, config);
};

export const deleteTenantSuperAdmin = (tenantId, userId, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.delete('tenants/' + tenantId + '/users/' + userId, config);
};

//Reports
export const getPlanBaseReports = token => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('/reports/planbase', config);
};

export const getPlanBaseReportsFromPlanId = (planId, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('/reports/planbase/' + planId, config);
};

//Dvices
export const getTenantDevices = (tenantId, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('/tenants/' + tenantId + '/devices', config);
};

export const creatNewDevice = (tenantId, deviceInfor, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.post('/tenants/' + tenantId + '/devices', deviceInfor, config);
};

export const updateDevice = (tenantId, deviceInfor, deviceId, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.put('/tenants/' + tenantId + '/devices/' + deviceId, deviceInfor, config);
};

export const deleteDevice = (tenantId, deviceId, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.delete('/tenants/' + tenantId + '/devices/' + deviceId, config);
};

export const getDeviceTypes = (tenantId, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('/tenants/' + tenantId + '/device_types/', config);
};

//Common
export const getCountries = token => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('/common/countries', config);
};

export const getStates = (countryId, token) => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('/common/countries/' + countryId + '/states', config);
};

export const getTimeZones = token => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('/common/timezones', config);
};

//Dashboard
export const geDashboardData = token => {
  let config = {
    headers: { authorization: token === null ? localStorage.getItem('token') : token }
  };

  return API.get('/dashboard/', config);
};
