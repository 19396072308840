import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../containers/customer/dashboard/Dashboard';
// import DashboardAlt from '../components/dashboard-alt/DashboardAlt';
import NavbarTop from '../containers/customer/navbar/NavbarTop';
import NavbarVertical from '../containers/customer/navbar/CustomerNavbarVertical';
import Footer from '../components/footer/Footer';
import loadable from '@loadable/component';
import AppContext from '../context/Context';

const CustomerDashboardRoutes = loadable(() => import('../containers/customer/routes/CustomerDashboardRoutes'));

const CustomerDashboardLayout = ({ location }) => {
  const { isFluid } = useContext(AppContext);

  useEffect(() => {
    CustomerDashboardRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      <NavbarVertical />
      <div className="content">
        <NavbarTop />
        <Switch>
          <Route path="/customer" exact component={Dashboard} />
          {/* <Route path="/" exact component={Dashboard} />
            <Route path="/dashboard-alt" exact component={DashboardAlt} /> */}
          <CustomerDashboardRoutes />
        </Switch>
        <Footer />
      </div>
    </div>
  );
};

CustomerDashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default CustomerDashboardLayout;
