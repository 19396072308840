import { CUSTOMER_ASSET_FIELDS_START, CUSTOMER_ASSET_FIELDS_SUCCESS, CUSTOMER_ASSET_FIELDS_FAIL } from '../types/types';
import { getAssetFields } from '../../../services/customer/customer';

export const assetFieldStart = () => {
  return {
    type: CUSTOMER_ASSET_FIELDS_START,
    payload: { error: null }
  };
};

export const assetFieldSuccess = status => {
  return {
    type: CUSTOMER_ASSET_FIELDS_SUCCESS,
    payload: status
  };
};

export const assetFieldFail = error => {
  return {
    type: CUSTOMER_ASSET_FIELDS_FAIL,
    payload: { error }
  };
};

export const assetFields = token => {
  return dispatch => {
    dispatch(assetFieldStart());

    getAssetFields(token)
      .then(response => {
        dispatch(assetFieldSuccess(response.data));
      })
      .catch(err => {
        console.log(err.reponse);
        try {
          dispatch(assetFieldFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(assetFieldFail('Please try again later..'));
        }
      });
  };
};
