import { CUSTOMER_AUTH_START, CUSTOMER_AUTH_FAIL, CUSTOMER_AUTH_SUCCESS, CUSTOMER_AUTH_OUT } from '../types/types';
import { signIn } from '../../../services/customer/customer';

export const authStart = () => {
  return {
    type: CUSTOMER_AUTH_START,
    payload: { error: null }
  };
};

export const authSuccess = (customerToken, customerRefreshToken, customerUserId, customerUserType, avatar) => {
  return {
    type: CUSTOMER_AUTH_SUCCESS,
    payload: { customerToken, customerRefreshToken, customerUserId, customerUserType, avatar }
  };
};

export const authFail = error => {
  return {
    type: CUSTOMER_AUTH_FAIL,
    payload: { error }
  };
};

export const logout = () => {
  localStorage.removeItem('customerToken');
  localStorage.removeItem('customerRefreshToken');
  localStorage.removeItem('customerUserId');
  localStorage.removeItem('customerUserType');
  localStorage.removeItem('customerRememberMe');
  localStorage.removeItem('slug');

  return {
    type: CUSTOMER_AUTH_OUT
  };
};

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const auth = (email, password, rememberMe, slug) => {
  return dispatch => {
    dispatch(authStart());

    const authData = {
      email: email,
      password: password
    };

    signIn(authData)
      .then(response => {
        // console.log(response);

        if (rememberMe) {
          localStorage.setItem('customerToken', response.data.token);
        }

        localStorage.setItem('slug', slug);
        localStorage.setItem('customerRememberMe', rememberMe);
        localStorage.setItem('customerRefreshToken', response.data.refresh_token);
        localStorage.setItem('customerUserId', response.data.user.id);
        localStorage.setItem('customerUserType', 'customer');

        dispatch(
          authSuccess(response.data.token, response.data.refresh_token, response.data.user.id, 'customer', null)
        );
      })
      .catch(err => {
        try {
          dispatch(authFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(authFail('Please try again later..'));
        }
      });
  };
};

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('customerUserType');
    const userId = localStorage.getItem('customerUserId');
    if (!token) {
      dispatch(logout());
    } else if (!userId) {
      dispatch(logout());
    } else {
      dispatch(authSuccess(token, userId));
    }
  };
};
