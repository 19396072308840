import { SSG_ROLE_START, SSG_ROLE_SUCCESS, SSG_ROLE_FAIL } from '../types/types';
import * as actions from '../../../services/ssg/ssg';

export const rolsStart = () => {
  return {
    type: SSG_ROLE_START,
    payload: { error: null }
  };
};

export const rolsSuccess = status => {
  return {
    type: SSG_ROLE_SUCCESS,
    payload: status
  };
};

export const rolsFail = error => {
  return {
    type: SSG_ROLE_FAIL,
    payload: { error }
  };
};

export const role = token => {
  return dispatch => {
    dispatch(rolsStart());

    actions
      .getRoles(token)
      .then(response => {
        dispatch(rolsSuccess(response.data));
      })
      .catch(err => {
        try {
          dispatch(rolsFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(rolsFail('Please try again later..'));
        }
      });
  };
};
