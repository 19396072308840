import {
  CUSTOMER_LOCATION_STATUS_START,
  CCUSTOMER_LOCATION_STATUS_FAIL,
  CUSTOMER_LOCATION_STATUS_SUCCESS
} from '../types/types';
import { getLocationStatus } from '../../../services/customer/customer';

export const locationStatusStart = () => {
  return {
    type: CUSTOMER_LOCATION_STATUS_START,
    payload: { error: null }
  };
};

export const locationStatusSuccess = status => {
  return {
    type: CUSTOMER_LOCATION_STATUS_SUCCESS,
    payload: status
  };
};

export const locationStatusFail = error => {
  return {
    type: CCUSTOMER_LOCATION_STATUS_FAIL,
    payload: { error }
  };
};

export const locationStatus = token => {
  return dispatch => {
    dispatch(locationStatusStart());

    getLocationStatus(token)
      .then(response => {
        dispatch(locationStatusSuccess(response.data));
      })
      .catch(err => {
        try {
          dispatch(locationStatusFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(locationStatusFail('Please try again later..'));
        }
      });
  };
};
