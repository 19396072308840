import { SSG_PLAN_START, SSG_PLAN_SUCCESS, SSG_PLAN_FAIL } from '../types/types';
import { getPlans } from '../../../services/ssg/ssg';

export const plansStart = () => {
  return {
    type: SSG_PLAN_START,
    payload: { error: null }
  };
};

export const plansSuccess = status => {
  return {
    type: SSG_PLAN_SUCCESS,
    payload: status
  };
};

export const plansFail = error => {
  return {
    type: SSG_PLAN_FAIL,
    payload: { error }
  };
};

export const plans = token => {
  return dispatch => {
    dispatch(plansStart());

    getPlans(token)
      .then(response => {
        dispatch(plansSuccess(response.data));
      })
      .catch(err => {
        try {
          dispatch(plansFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(plansFail('Please try again later..'));
        }
      });
  };
};
