import { CUSTOMER_ZONE_STATUS_START, CCUSTOMER_ZONE_STATUS_FAIL, CUSTOMER_ZONE_STATUS_SUCCESS } from '../types/types';
import { getZoneStatus } from '../../../services/customer/customer';

export const zoneStatusStart = () => {
  return {
    type: CUSTOMER_ZONE_STATUS_START,
    payload: { error: null }
  };
};

export const zoneStatusSuccess = status => {
  return {
    type: CUSTOMER_ZONE_STATUS_SUCCESS,
    payload: status
  };
};

export const zoneStatusFail = error => {
  return {
    type: CCUSTOMER_ZONE_STATUS_FAIL,
    payload: { error }
  };
};

export const zoneStatus = token => {
  return dispatch => {
    dispatch(zoneStatusStart());

    getZoneStatus(token)
      .then(response => {
        dispatch(zoneStatusSuccess(response.data));
      })
      .catch(err => {
        try {
          dispatch(zoneStatusFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(zoneStatusFail('Please try again later..'));
        }
      });
  };
};
