import { version } from '../../../config/config';

export const homeRoutes = {
  name: 'Dashboard',
  to: '/customer',
  exact: true,
  icon: 'chart-pie'
};

export const assertManagementRoutes = {
  name: 'Asset Management',
  to: '/customer/assetsmanagement',
  icon: 'puzzle-piece',
  children: [
    { to: '/customer/assetsmanagement/locations', name: 'Locations' },
    { to: '/customer/assetsmanagement/categories', name: 'Categories' },
    { to: '/customer/assetsmanagement/containers', name: 'Containers' },
    { to: '/customer/assetsmanagement/assets', name: 'Assets' },
    { to: '/customer/assetsmanagement/zones', name: 'Zones' },
    { to: '/customer/assetsmanagement/devices', name: 'Devices' },
    { to: '/customer/assetsmanagement/unauthaccess', name: 'Unauthorized Access' }
  ]
};

export const userManagementRoutes = {
  name: 'User Management',
  to: '/customer/usermanagement',
  icon: 'users',
  children: [
    { to: '/customer/usermanagement/groups', name: 'Groups' },
    { to: '/customer/usermanagement/users', name: 'Users' }
  ]
};

export const inventoryRoutes = {
  name: 'Inventory',
  to: '/customer/inventory/',
  exact: true,
  icon: 'book'
};

export const reportsRoutes = {
  name: 'Reports',
  to: '/customer/reports',
  exact: true,
  icon: 'stream'
};

export default [homeRoutes, assertManagementRoutes, userManagementRoutes, inventoryRoutes, reportsRoutes];
