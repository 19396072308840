import React, { useContext, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import echarts from 'echarts/lib/echarts';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import { getPosition, getGrays, themeColors, rgbaColor, capitalize } from '../../../helpers/utils';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import AppContext from '../../../context/Context';
import moment from 'moment';
import _ from 'lodash';

import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';

const TenantRegistrationTrend = data => {
  const { isDark } = useContext(AppContext);
  const [month, setMonth] = useState(_.toLower(moment().format('MMMM')));
  const [monthlydata, setmonthlydata] = useState({});

  const getFormatter = params => {
    const { name, value } = params[0];
    return `${Object.keys(data.data)} ${name}, ${value}`;
  };

  const getOption = (month, isDark) => {
    const grays = getGrays(isDark);
    return {
      tooltip: {
        trigger: 'axis',
        padding: [7, 10],
        backgroundColor: grays['300'],
        borderColor: grays['300'],
        borderWidth: 1,
        textStyle: { color: themeColors.dark },
        formatter(params) {
          return getFormatter(params);
        },
        transitionDuration: 0,
        position(pos, params, dom, rect, size) {
          return getPosition(pos, params, dom, rect, size);
        }
      },
      xAxis: {
        type: 'category',
        data: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31],
        boundaryGap: false,
        axisPointer: {
          lineStyle: {
            color: grays['300'],
            type: 'dashed'
          }
        },
        splitLine: { show: false },
        axisLine: {
          lineStyle: {
            color: rgbaColor('#000', 0.01),
            type: 'dashed'
          }
        },
        axisTick: { show: false },
        axisLabel: {
          color: grays['400'],
          formatter: function(value) {
            return `${capitalize(month !== null && month.slice(0, 3))} ${value}`;
          },
          margin: 15
        }
      },
      yAxis: {
        type: 'value',
        axisPointer: { show: false },
        splitLine: {
          lineStyle: {
            color: grays['300'],
            type: 'dashed'
          }
        },
        boundaryGap: false,
        axisLabel: {
          show: true,
          color: grays['400'],
          margin: 15
        },
        axisTick: { show: false },
        axisLine: { show: false }
      },
      series: [
        {
          type: 'line',
          data: data.data[month],
          lineStyle: { color: themeColors.primary },
          itemStyle: {
            color: grays['100'],
            borderColor: themeColors.primary,
            borderWidth: 2
          },
          symbol: 'circle',
          symbolSize: 10,
          smooth: false,
          hoverAnimation: true,
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: rgbaColor(themeColors.primary, 0.2)
                },
                {
                  offset: 1,
                  color: rgbaColor(themeColors.primary, 0)
                }
              ]
            }
          }
        }
      ],
      animationDuration: 90000,
      grid: { right: '28px', left: '40px', bottom: '15%', top: '5%' }
    };
  };

  return (
    <Card className="h-lg-100 overflow-hidden">
      <FalconCardHeader title="Tenant Registration Trend" light={false} titleTag="h6" className="pb-0" />

      <CardBody className="h-100">
        <ReactEchartsCore echarts={echarts} option={getOption(month, isDark)} style={{ minHeight: '18.75rem' }} />
      </CardBody>
    </Card>
  );
};

export default TenantRegistrationTrend;
