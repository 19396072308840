import product12 from '../../assets/img/products/12.jpg';
import product10 from '../../assets/img/products/10.jpg';
import product11 from '../../assets/img/products/11.jpg';
import product14 from '../../assets/img/products/14.jpg';
import product13 from '../../assets/img/products/13.jpg';

export default [
  {
    id: 1,
    img: product12,
    title: 'Raven Pro',
    type: 'Landing',
    unit: 19,
    price: 69,
    progress: 38,
    time: '12:50:00',
    color: 'warning',
    date: '01/08/2021',
    plandate: '01/06/2021',
    plan: 'Gold Plus',
    company: 'Cenango'
  },
  {
    id: 2,
    img: product10,
    title: 'Boots4',
    type: 'Portfolio',
    unit: 10,
    price: 86,
    progress: 79,
    time: '25:20:00',
    color: 'info',
    date: '01/12/2021',
    plandate: '01/05/2021',
    plan: 'Gold',
    company: 'Cemex'
  },
  {
    id: 3,
    img: product11,
    title: 'Falcon',
    type: 'Admin',
    unit: 11,
    price: 49,
    progress: 90,
    time: '58:20:00',
    color: 'primary',
    date: '01/14/2021',
    plandate: '01/04/2021',
    plan: 'Silver',
    company: 'Globex Corporation'
  },
  {
    id: 4,
    img: product14,
    title: 'Slick',
    type: 'Builder',
    unit: 5,
    price: 49,
    progress: 40,
    time: '21:20:00',
    color: 'danger',
    date: '01/16/2021',
    plandate: '01/03/2021',
    plan: 'Silver Plus',
    company: 'Soylent Corp'
  },
  {
    id: 5,
    img: product13,
    title: 'Reign Pro',
    type: 'Agency',
    unit: 6,
    price: 39,
    progress: 78,
    time: '31:50:00',
    color: 'success',
    date: '01/20/2021',
    plandate: '01/02/2021',
    plan: 'Bronze Plus',
    company: 'Initech'
  }
];
