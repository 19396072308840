/*AUTH TYPES*/
export const CUSTOMER_AUTH_START = 'CUSTOMER_AUTH_START';
export const CUSTOMER_AUTH_FAIL = 'CUSTOMER_AUTH_FAIL';
export const CUSTOMER_AUTH_SUCCESS = 'CUSTOMER_AUTH_SUCCESS';
export const CUSTOMER_AUTH_OUT = 'CUSTOMER_AUTH_OUT';
export const CUSTOMER_CHECK_AUTHORIZATION = 'CUSTOMER_CHECK_AUTHORIZATION';

export const CUSTOMER_CONFIRM_MAIL = 'CUSTOMER_CONFIRM_MAIL';

export const CUSTOMER_STATUS_START = 'CUSTOMER_STATUS_START';
export const CUSTOMER_STATUS_FAIL = 'CUSTOMER_STATUS_FAIL';
export const CUSTOMER_STATUS_SUCCESS = 'CUSTOMER_STATUS_SUCCESS';

export const CUSTOMER_ROLE_START = 'CUSTOMER_ROLE_START';
export const CUSTOMER_ROLE_FAIL = 'CUSTOMER_ROLE_FAIL';
export const CUSTOMER_ROLE_SUCCESS = 'CUSTOMER_ROLE_SUCCESS';

export const CUSTOMER_GROUP_START = 'CUSTOMER_GROUP_START';
export const CUSTOMER_GROUP_FAIL = 'CUSTOMER_GROUP_FAIL';
export const CUSTOMER_GROUP_SUCCESS = 'CUSTOMER_GROUP_SUCCESS';

export const CUSTOMER_CATEGORY_FIELDS_START = 'CUSTOMER_CATEGORY_FIELDS_START';
export const CUSTOMER_CATEGORY_FIELDS_SUCCESS = 'CUSTOMER_CATEGORY_FIELDS_SUCCESS';
export const CUSTOMER_CATEGORY_FIELDS_FAIL = 'CUSTOMER_CATEGORY_FIELDS_FAIL';

export const CUSTOMER_CONTAINER_FIELDS_START = 'CUSTOMER_CONTAINER_FIELDS_START';
export const CUSTOMER_CONTAINER_FIELDS_SUCCESS = 'CUSTOMER_CONTAINER_FIELDS_SUCCESS';
export const CUSTOMER_CONTAINER_FIELDS_FAIL = 'CUSTOMER_CONTAINER_FIELDS_FAIL';

export const CUSTOMER_LOCATIONS_START = 'CUSTOMER_LOCATIONS_START';
export const CUSTOMER_LOCATIONS_SUCCESS = 'CUSTOMER_LOCATIONS_SUCCESS';
export const CUSTOMER_LOCATIONS_FAIL = 'CUSTOMER_LOCATIONS_FAIL';

export const CUSTOMER_LOCATION_STATUS_START = 'CUSTOMER_LOCATION_STATUS_START';
export const CCUSTOMER_LOCATION_STATUS_FAIL = 'CCUSTOMER_LOCATION_STATUS_FAIL';
export const CUSTOMER_LOCATION_STATUS_SUCCESS = 'CUSTOMER_LOCATION_STATUS_SUCCESS';

export const CUSTOMER_CATEGORY_STATUS_START = 'CUSTOMER_CATEGORY_STATUS_START';
export const CCUSTOMER_CATEGORY_STATUS_FAIL = 'CCUSTOMER_CATEGORY_STATUS_FAIL';
export const CUSTOMER_CATEGORY_STATUS_SUCCESS = 'CUSTOMER_CATEGORY_STATUS_SUCCESS';

export const CUSTOMER_CATEGORY_FIELD_STATUS_START = 'CUSTOMER_CATEGORY_FIELD_STATUS_START';
export const CCUSTOMER_CATEGORY_FIELD_STATUS_FAIL = 'CCUSTOMER_CATEGORY_FIELD_STATUS_FAIL';
export const CUSTOMER_CATEGORY_FIELD_STATUS_SUCCESS = 'CUSTOMER_CATEGORY_FIELD_STATUS_SUCCESS';

export const CUSTOMER_CATEGORY_START = 'CUSTOMER_CATEGORY_START';
export const CUSTOMER_CATEGORY_FAIL = 'CUSTOMER_CATEGORY_FAIL';
export const CUSTOMER_CATEGORY_SUCCESS = 'CUSTOMER_CATEGORY_SUCCESS';

export const CUSTOMER_CONTAINER_START = 'CUSTOMER_CONTAINER_START';
export const CUSTOMER_CONTAINER_SUCCESS = 'CUSTOMER_CONTAINER_SUCCESS';
export const CUSTOMER_CONTAINER_FAIL = 'CUSTOMER_CONTAINER_FAIL';

export const CUSTOMER_ASSET_FIELDS_START = 'CUSTOMER_ASSET_FIELDS_START';
export const CUSTOMER_ASSET_FIELDS_SUCCESS = 'CUSTOMER_ASSET_FIELDS_SUCCESS';
export const CUSTOMER_ASSET_FIELDS_FAIL = 'CUSTOMER_ASSET_FIELDS_FAIL';

export const CUSTOMER_CHILD_CONTAINER_START = 'CUSTOMER_CHILD_CONTAINER_START';
export const CUSTOMER_CHILD_CONTAINER_SUCCESS = 'CUSTOMER_CHILD_CONTAINER_SUCCESS';
export const CUSTOMER_CHILD_CONTAINER_FAIL = 'CUSTOMER_CHILD_CONTAINER_FAIL';

export const CUSTOMER_NOTIFICATION_START = 'CUSTOMER_NOTIFICATION_START';
export const CUSTOMER_NOTIFICATION_SUCCESS = 'CUSTOMER_NOTIFICATION_SUCCESS';
export const CUSTOMER_NOTIFICATION_FAIL = 'CUSTOMER_NOTIFICATION_FAIL';

export const CUSTOMER_REPORT_FILTER_START = 'CUSTOMER_REPORT_FILTER_START';
export const CUSTOMER_REPORT_FILTER_SUCCESS = 'CUSTOMER_REPORT_FILTER_SUCCESS';
export const CUSTOMER_REPORT_FILTER_FAIL = 'CUSTOMER_REPORT_FILTER_FAIL';

export const CUSTOMER_ASSET_STATUS_START = 'CUSTOMER_ASSET_STATUS_START';
export const CCUSTOMER_ASSET_STATUS_FAIL = 'CCUSTOMER_ASSET_STATUS_FAIL';
export const CUSTOMER_ASSET_STATUS_SUCCESS = 'CUSTOMER_ASSET_STATUS_SUCCESS';

export const CUSTOMER_CONTAINER_STATUS_START = 'CUSTOMER_CONTAINER_STATUS_START';
export const CCUSTOMER_CONTAINER_STATUS_FAIL = 'CCUSTOMER_CONTAINER_STATUS_FAIL';
export const CUSTOMER_CONTAINER_STATUS_SUCCESS = 'CUSTOMER_CONTAINER_STATUS_SUCCESS';

export const CUSTOMER_COMMON_STATES_START = 'CUSTOMER_COMMON_STATES_START';
export const CUSTOMER_COMMON_STATES_FAIL = 'CUSTOMER_COMMON_STATES_FAIL';
export const CUSTOMER_COMMON_STATES_SUCCESS = 'CUSTOMER_COMMON_STATES_SUCCESS';

export const CUSTOMER_ZONE_START = 'CUSTOMER_ZONE_START';
export const CCUSTOMER_ZONE_FAIL = 'CCUSTOMER_ZONE_FAIL';
export const CUSTOMER_ZONE_SUCCESS = 'CUSTOMER_ZONE_SUCCESS';

export const CUSTOMER_ZONE_STATUS_START = 'CUSTOMER_ZONE_STATUS_START';
export const CCUSTOMER_ZONE_STATUS_FAIL = 'CCUSTOMER_ZONE_STATUS_FAIL';
export const CUSTOMER_ZONE_STATUS_SUCCESS = 'CUSTOMER_ZONE_STATUS_SUCCESS';

export const CUSTOMER_UNAUTH_NOTIFICATION_START = 'CUSTOMER_UNAUTH_NOTIFICATION_START';
export const CUSTOMER_UNAUTH_NOTIFICATION_SUCCESS = 'CUSTOMER_UNAUTH_NOTIFICATION_SUCCESS';
export const CUSTOMER_UNAUTH_NOTIFICATION_FAIL = 'CUSTOMER_UNAUTH_NOTIFICATION_FAIL';
