import { ContainerFieldStart } from '../../actions/customer/assetManagement/ContainerFieldActions';
import * as actionTypes from '../../actions/customer/types/types';
import { updateObject } from '../../reducers/utility/Utility';

const initialState = {
  /*Auth */
  customerToken: null,
  customerRefreshToken: null,
  customerUserId: null,
  customerUserType: null,
  authError: null,
  authLoading: false,

  confirmMail: null,

  statusLoading: false,
  status: null,
  statusError: null,

  rolesLoading: false,
  roles: null,
  rolesError: null,

  groupsLoading: false,
  groups: null,
  groupsError: null,

  categoryFieldLoading: false,
  categoryFields: null,
  categoryFieldError: null,

  containerFieldLoading: false,
  containerFields: null,
  containerFieldError: null,

  locationsLoading: false,
  locations: null,
  locationsError: null,

  locationStatusLoading: false,
  locationStatus: null,
  locationStatusError: null,

  categoryStatusLoading: false,
  categoryStatus: null,
  categoryStatusError: null,

  categoryFieldStatusLoading: false,
  categoryFieldStatus: null,
  categoryFieldStatusError: null,

  categoriesLoading: false,
  categories: null,
  categoriesError: null,

  containersLoading: false,
  containers: null,
  containersError: null,

  assetFieldLoading: false,
  assetFields: null,
  assetFieldError: null,

  childContainersLoading: false,
  childContainers: null,
  childContainersError: null,

  notificationsLoading: false,
  notifications: null,
  notificationsError: null,

  reportFiltersLoading: false,
  reportFilters: null,
  reportFiltersError: null,

  assetStatusLoading: false,
  assetStatus: null,
  assetStatusError: null,

  containerStatusLoading: false,
  containerStatus: null,
  containerStatusError: null,

  statesLoading: false,
  states: null,
  statesError: null,

  zoneStatusLoading: false,
  zoneStatus: null,
  zoneStatusError: null,

  zonesLoading: false,
  zones: null,
  zonesError: null,

  unAuthNotificationsLoading: false,
  unAuthNotifications: null,
  unAuthNotificationsError: null
};

/*Auth */
const authStart = (state, action) => {
  return updateObject(state, { authError: null, authLoading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    customerToken: action.payload.customerToken,
    customerRefreshToken: action.payload.customerRefreshToken,
    customerUserId: action.payload.customerUserId,
    customerUserType: action.payload.customerUserType,
    authError: null,
    authLoading: false
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    authError: action.payload.error,
    authLoading: false
  });
};

const authOut = (state, action) => {
  return updateObject(state, {
    customerToken: null,
    customerUserId: null,
    customerUserType: null,
    authError: null,
    authLoading: false
  });
};

const storConfirmMail = (state, action) => {
  return updateObject(state, {
    confirmMail: action.payload.email
  });
};

const statusStart = (state, action) => {
  return updateObject(state, { statusError: null, statusLoading: true });
};

const statusSuccess = (state, action) => {
  return updateObject(state, { status: action.payload, statusLoading: false, statusError: null });
};

const statusFail = (state, action) => {
  return updateObject(state, {
    statusError: action.payload.error,
    statusLoading: false
  });
};

const rolsStart = (state, action) => {
  return updateObject(state, { rolesError: null, rolesLoading: true });
};

const rolsSuccess = (state, action) => {
  return updateObject(state, { roles: action.payload, rolesLoading: false, rolesError: null });
};

const rolsFail = (state, action) => {
  return updateObject(state, {
    rolesError: action.payload.error,
    rolesLoading: false
  });
};

const groupsStart = (state, action) => {
  return updateObject(state, { groupsError: null, groupsLoading: true });
};

const groupsSuccess = (state, action) => {
  return updateObject(state, { groups: action.payload, groupsLoading: false, groupsError: null });
};

const groupsFail = (state, action) => {
  return updateObject(state, {
    groupsError: action.payload.error,
    groupsLoading: false
  });
};

const categoryFieldStart = (state, action) => {
  return updateObject(state, { categoryFieldError: null, categoryFieldLoading: true });
};

const categoryFieldSuccess = (state, action) => {
  return updateObject(state, { categoryFields: action.payload, categoryFieldLoading: false, categoryFieldError: null });
};

const categoryFieldsFail = (state, action) => {
  return updateObject(state, {
    categoryFieldError: action.payload.error,
    categoryFieldLoading: false
  });
};

const containerFieldStart = (state, action) => {
  return updateObject(state, { containerFieldError: null, containerFieldLoading: true });
};

const containerFieldSuccess = (state, action) => {
  return updateObject(state, {
    containerFields: action.payload,
    containerFieldLoading: false,
    containerFieldError: null
  });
};

const containerFieldsFail = (state, action) => {
  return updateObject(state, {
    containerFieldError: action.payload.error,
    containerFieldLoading: false
  });
};

const locationsStart = (state, action) => {
  return updateObject(state, { locationsError: null, locationsLoading: true });
};

const locationsSuccess = (state, action) => {
  return updateObject(state, {
    locations: action.payload,
    locationsLoading: false,
    locationsError: null
  });
};

const locationsFail = (state, action) => {
  return updateObject(state, {
    containerFieldError: action.payload.error,
    locationsError: false
  });
};

const locationStatusStart = (state, action) => {
  return updateObject(state, { locationStatusError: null, locationStatusLoading: true });
};

const locationStatusSuccess = (state, action) => {
  return updateObject(state, {
    locationStatus: action.payload,
    locationStatusLoading: false,
    locationStatusError: null
  });
};

const locationStatusFail = (state, action) => {
  return updateObject(state, {
    locationStatusError: action.payload.error,
    locationStatusLoading: false
  });
};

const categoryStatusStart = (state, action) => {
  return updateObject(state, { categoryStatusError: null, categoryStatusLoading: true });
};

const categoryStatusSuccess = (state, action) => {
  return updateObject(state, {
    categoryStatus: action.payload,
    categoryStatusLoading: false,
    categoryStatusError: null
  });
};

const categoryStatusFail = (state, action) => {
  return updateObject(state, {
    categoryStatusError: action.payload.error,
    categoryStatusLoading: false
  });
};

const categoryFieldStatusStart = (state, action) => {
  return updateObject(state, { categoryFieldStatusError: null, categoryFieldStatusLoading: true });
};

const categoryFieldStatusSuccess = (state, action) => {
  return updateObject(state, {
    categoryFieldStatus: action.payload,
    categoryFieldStatusLoading: false,
    categoryFieldStatusError: null
  });
};

const categoryFieldStatusFail = (state, action) => {
  return updateObject(state, {
    categoryFieldStatusError: action.payload.error,
    categoryFieldStatusLoading: false
  });
};

const categoriesStart = (state, action) => {
  return updateObject(state, { categoriesError: null, categoriesLoading: true });
};

const categoriesSuccess = (state, action) => {
  return updateObject(state, {
    categories: action.payload,
    categoriesLoading: false,
    categoriesError: null
  });
};

const categoriesFail = (state, action) => {
  return updateObject(state, {
    categoriesError: action.payload.error,
    categoriesLoading: false
  });
};

const containersStart = (state, action) => {
  return updateObject(state, { containersError: null, containersLoading: true });
};

const containersSuccess = (state, action) => {
  return updateObject(state, {
    containers: action.payload,
    containersLoading: false,
    containersError: null
  });
};

const containersFail = (state, action) => {
  return updateObject(state, {
    containersError: action.payload.error,
    containersLoading: false
  });
};

const assetFieldStart = (state, action) => {
  return updateObject(state, { assetFieldError: null, assetFieldLoading: true });
};

const assetFieldSuccess = (state, action) => {
  return updateObject(state, {
    assetFields: action.payload,
    assetFieldLoading: false,
    assetFieldError: null
  });
};

const assetFieldFail = (state, action) => {
  return updateObject(state, {
    assetFieldError: action.payload.error,
    assetFieldLoading: false
  });
};

const childContainersStart = (state, action) => {
  return updateObject(state, { childContainersError: null, childContainersLoading: true });
};

const childContainersSuccess = (state, action) => {
  return updateObject(state, {
    childContainers: action.payload,
    childContainersLoading: false,
    childContainersError: null
  });
};

const childContainersFail = (state, action) => {
  return updateObject(state, {
    childContainersError: action.payload.error,
    childContainersLoading: false
  });
};

const notificationsStart = (state, action) => {
  return updateObject(state, { notificationsError: null, notificationsLoading: true });
};

const notificationsSuccess = (state, action) => {
  return updateObject(state, {
    notifications: action.payload,
    notificationsLoading: false,
    notificationsError: null
  });
};

const notificationsFail = (state, action) => {
  return updateObject(state, {
    notificationsError: action.payload.error,
    notificationsLoading: false
  });
};

const reportFiltersStart = (state, action) => {
  return updateObject(state, { reportFiltersError: null, reportFiltersLoading: true });
};

const reportFiltersSuccess = (state, action) => {
  return updateObject(state, {
    reportFilters: action.payload,
    reportFiltersLoading: false,
    reportFiltersError: null
  });
};

const reportFiltersFail = (state, action) => {
  return updateObject(state, {
    reportFiltersError: action.payload.error,
    reportFiltersLoading: false
  });
};

const assetStatusStart = (state, action) => {
  return updateObject(state, { assetStatusError: null, assetStatusLoading: true });
};

const assetStatusSuccess = (state, action) => {
  return updateObject(state, {
    assetStatus: action.payload,
    assetStatusLoading: false,
    assetStatusError: null
  });
};

const assetStatusFail = (state, action) => {
  return updateObject(state, {
    assetStatusError: action.payload.error,
    assetStatusLoading: false
  });
};

const containerStatusStart = (state, action) => {
  return updateObject(state, { containerStatusError: null, containerStatusLoading: true });
};

const containerStatusSuccess = (state, action) => {
  return updateObject(state, {
    containerStatus: action.payload,
    containerStatusLoading: false,
    containerStatusError: null
  });
};

const containerStatusFail = (state, action) => {
  return updateObject(state, {
    containerStatusError: action.payload.error,
    containerStatusLoading: false
  });
};

const statesStart = (state, action) => {
  return updateObject(state, { statesError: null, statesLoading: true });
};

const statesSuccess = (state, action) => {
  return updateObject(state, {
    states: action.payload,
    statesLoading: false,
    statesError: null
  });
};

const statesFail = (state, action) => {
  return updateObject(state, {
    statesError: action.payload.error,
    statesLoading: false
  });
};

const zoneStatusStart = (state, action) => {
  return updateObject(state, { zoneStatusError: null, zoneStatusLoading: true });
};

const zoneStatusSuccess = (state, action) => {
  return updateObject(state, {
    zoneStatus: action.payload,
    zoneStatusLoading: false,
    zoneStatusError: null
  });
};

const zoneStatusFail = (state, action) => {
  return updateObject(state, {
    zoneStatusError: action.payload.error,
    zoneStatusLoading: false
  });
};

const zonesStart = (state, action) => {
  return updateObject(state, { zonesError: null, zonesLoading: true });
};

const zonesSuccess = (state, action) => {
  return updateObject(state, {
    zones: action.payload,
    zonesLoading: false,
    zonesError: null
  });
};

const zonesFail = (state, action) => {
  return updateObject(state, {
    zonesError: action.payload.error,
    zonesLoading: false
  });
};

const unAuthNotificationsStart = (state, action) => {
  return updateObject(state, { unAuthNotificationsError: null, unAuthNotificationsLoading: true });
};

const unAuthNotificationsSuccess = (state, action) => {
  return updateObject(state, {
    unAuthNotifications: action.payload,
    unAuthNotificationsLoading: false,
    unAuthNotificationsError: null
  });
};

const unAuthNotificationsFail = (state, action) => {
  return updateObject(state, {
    unAuthNotificationsError: action.payload.error,
    unAuthNotificationsLoading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CUSTOMER_AUTH_START:
      return authStart(state, action);

    case actionTypes.CUSTOMER_AUTH_SUCCESS:
      return authSuccess(state, action);

    case actionTypes.CUSTOMER_AUTH_FAIL:
      return authFail(state, action);

    case actionTypes.CUSTOMER_AUTH_OUT:
      return authOut(state, action);

    case actionTypes.CUSTOMER_CONFIRM_MAIL:
      return storConfirmMail(state, action);

    case actionTypes.CUSTOMER_STATUS_START:
      return statusStart(state, action);

    case actionTypes.CUSTOMER_STATUS_SUCCESS:
      return statusSuccess(state, action);

    case actionTypes.CUSTOMER_STATUS_FAIL:
      return statusFail(state, action);

    case actionTypes.CUSTOMER_ROLE_START:
      return rolsStart(state, action);

    case actionTypes.CUSTOMER_ROLE_SUCCESS:
      return rolsSuccess(state, action);

    case actionTypes.CUSTOMER_ROLE_FAIL:
      return rolsFail(state, action);

    case actionTypes.CUSTOMER_GROUP_START:
      return groupsStart(state, action);

    case actionTypes.CUSTOMER_GROUP_SUCCESS:
      return groupsSuccess(state, action);

    case actionTypes.CUSTOMER_GROUP_FAIL:
      return groupsFail(state, action);

    case actionTypes.CUSTOMER_CATEGORY_FIELDS_START:
      return categoryFieldStart(state, action);

    case actionTypes.CUSTOMER_CATEGORY_FIELDS_SUCCESS:
      return categoryFieldSuccess(state, action);

    case actionTypes.CUSTOMER_CATEGORY_FIELDS_FAIL:
      return categoryFieldsFail(state, action);

    case actionTypes.CUSTOMER_CONTAINER_FIELDS_START:
      return containerFieldStart(state, action);

    case actionTypes.CUSTOMER_CONTAINER_FIELDS_SUCCESS:
      return containerFieldSuccess(state, action);

    case actionTypes.CUSTOMER_CONTAINER_FIELDS_FAIL:
      return containerFieldsFail(state, action);

    case actionTypes.CUSTOMER_LOCATIONS_START:
      return locationsStart(state, action);

    case actionTypes.CUSTOMER_LOCATIONS_SUCCESS:
      return locationsSuccess(state, action);

    case actionTypes.CUSTOMER_LOCATIONS_FAIL:
      return locationsFail(state, action);

    case actionTypes.CUSTOMER_LOCATION_STATUS_START:
      return locationStatusStart(state, action);

    case actionTypes.CUSTOMER_LOCATION_STATUS_SUCCESS:
      return locationStatusSuccess(state, action);

    case actionTypes.CCUSTOMER_LOCATION_STATUS_FAIL:
      return locationStatusFail(state, action);

    case actionTypes.CUSTOMER_CATEGORY_STATUS_START:
      return categoryStatusStart(state, action);

    case actionTypes.CUSTOMER_CATEGORY_STATUS_SUCCESS:
      return categoryStatusSuccess(state, action);

    case actionTypes.CCUSTOMER_CATEGORY_STATUS_FAIL:
      return categoryStatusFail(state, action);

    case actionTypes.CUSTOMER_CATEGORY_FIELD_STATUS_START:
      return categoryFieldStatusStart(state, action);

    case actionTypes.CUSTOMER_CATEGORY_FIELD_STATUS_SUCCESS:
      return categoryFieldStatusSuccess(state, action);

    case actionTypes.CCUSTOMER_CATEGORY_FIELD_STATUS_FAIL:
      return categoryFieldStatusFail(state, action);

    case actionTypes.CUSTOMER_CATEGORY_START:
      return categoriesStart(state, action);

    case actionTypes.CUSTOMER_CATEGORY_SUCCESS:
      return categoriesSuccess(state, action);

    case actionTypes.CUSTOMER_CATEGORY_FAIL:
      return categoriesFail(state, action);

    case actionTypes.CUSTOMER_CONTAINER_START:
      return containersStart(state, action);

    case actionTypes.CUSTOMER_CONTAINER_SUCCESS:
      return containersSuccess(state, action);

    case actionTypes.CUSTOMER_CONTAINER_FAIL:
      return containersFail(state, action);

    case actionTypes.CUSTOMER_ASSET_FIELDS_START:
      return assetFieldStart(state, action);

    case actionTypes.CUSTOMER_ASSET_FIELDS_SUCCESS:
      return assetFieldSuccess(state, action);

    case actionTypes.CUSTOMER_ASSET_FIELDS_FAIL:
      return assetFieldFail(state, action);

    case actionTypes.CUSTOMER_CHILD_CONTAINER_START:
      return childContainersStart(state, action);

    case actionTypes.CUSTOMER_CHILD_CONTAINER_SUCCESS:
      return childContainersSuccess(state, action);

    case actionTypes.CUSTOMER_CHILD_CONTAINER_FAIL:
      return childContainersFail(state, action);

    case actionTypes.CUSTOMER_NOTIFICATION_START:
      return notificationsStart(state, action);

    case actionTypes.CUSTOMER_NOTIFICATION_SUCCESS:
      return notificationsSuccess(state, action);

    case actionTypes.CUSTOMER_NOTIFICATION_FAIL:
      return notificationsFail(state, action);

    case actionTypes.CUSTOMER_REPORT_FILTER_START:
      return reportFiltersStart(state, action);

    case actionTypes.CUSTOMER_REPORT_FILTER_SUCCESS:
      return reportFiltersSuccess(state, action);

    case actionTypes.CUSTOMER_REPORT_FILTER_FAIL:
      return reportFiltersFail(state, action);

    case actionTypes.CUSTOMER_ASSET_STATUS_START:
      return assetStatusStart(state, action);

    case actionTypes.CUSTOMER_ASSET_STATUS_SUCCESS:
      return assetStatusSuccess(state, action);

    case actionTypes.CCUSTOMER_ASSET_STATUS_FAIL:
      return assetStatusFail(state, action);

    case actionTypes.CUSTOMER_CONTAINER_STATUS_START:
      return containerStatusStart(state, action);

    case actionTypes.CUSTOMER_CONTAINER_STATUS_SUCCESS:
      return containerStatusSuccess(state, action);

    case actionTypes.CCUSTOMER_CONTAINER_STATUS_FAIL:
      return containerStatusFail(state, action);

    case actionTypes.CUSTOMER_COMMON_STATES_START:
      return statesStart(state, action);

    case actionTypes.CUSTOMER_COMMON_STATES_SUCCESS:
      return statesSuccess(state, action);

    case actionTypes.CUSTOMER_COMMON_STATES_FAIL:
      return statesFail(state, action);

    case actionTypes.CUSTOMER_ZONE_STATUS_START:
      return zoneStatusStart(state, action);

    case actionTypes.CUSTOMER_ZONE_STATUS_SUCCESS:
      return zoneStatusSuccess(state, action);

    case actionTypes.CCUSTOMER_ZONE_STATUS_FAIL:
      return zoneStatusFail(state, action);

    case actionTypes.CUSTOMER_ZONE_START:
      return zonesStart(state, action);

    case actionTypes.CUSTOMER_ZONE_SUCCESS:
      return zonesSuccess(state, action);

    case actionTypes.CCUSTOMER_ZONE_FAIL:
      return zonesFail(state, action);

    case actionTypes.CUSTOMER_UNAUTH_NOTIFICATION_START:
      return unAuthNotificationsStart(state, action);

    case actionTypes.CUSTOMER_UNAUTH_NOTIFICATION_SUCCESS:
      return unAuthNotificationsSuccess(state, action);

    case actionTypes.CUSTOMER_UNAUTH_NOTIFICATION_FAIL:
      return unAuthNotificationsFail(state, action);

    default:
      return state;
  }
};

export default reducer;
