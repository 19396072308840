import React from 'react';
import ForgetPasswordForm from '../ForgetPasswordForm';
import AuthCardLayout from '../../../../layouts/AuthCardLayout';

const ForgetPassword = () => {
  return (
    <AuthCardLayout
      leftSideContent={<p className="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">FetchRFID</p>}
    >
      <h4 className="mb-0"> Forgot your password?</h4>
      <p className="mb-0">Enter your email and we'll send you a reset link.</p>
      <ForgetPasswordForm layout="card" />
    </AuthCardLayout>
  );
};

export default ForgetPassword;
