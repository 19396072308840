import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Avatar from '../common/Avatar';
import moment from 'moment';
import createMarkup from '../../helpers/createMarkup';
import { Fragment } from 'react';

const Notification = ({ to, avatar, time, className, unread, flush, emoji, notification, status, added_time, id }) => {
  if (to === undefined) {
    to = '#';
  }

  if (time === undefined) {
    time = moment().format('LT');
  }

  if (status.code === 'UNREAD') unread = true;
  else unread = false;

  emoji = '💬';

  time = moment(added_time)
    .startOf('hour')
    .fromNow();

  return (
    <Fragment>
      <Link
        className={classNames('notification', { 'bg-200': unread, 'notification-flush': flush }, className)}
        to={to}
      >
        {avatar && (
          <div className="notification-avatar">
            <Avatar {...avatar} className="mr-3" />
            {id}
          </div>
        )}
        <div className="notification-body">
          <p className={emoji ? 'mb-1' : 'mb-0'} dangerouslySetInnerHTML={createMarkup(notification)} />
          <span className="notification-time">
            {emoji && (
              <span className="mr-1" role="img" aria-label="Emoji">
                {emoji}
              </span>
            )}
            {time}
          </span>
        </div>
      </Link>
    </Fragment>
  );
};

Notification.propTypes = {
  to: PropTypes.string,
  avatar: PropTypes.shape(Avatar.propTypes),
  time: PropTypes.string,
  className: PropTypes.string,
  unread: PropTypes.bool,
  flush: PropTypes.bool,
  emoji: PropTypes.string,
  notification: PropTypes.node
};

Notification.defaultProps = { unread: false, flush: false };

export default Notification;
