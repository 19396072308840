import React, { Fragment } from 'react';
import LoginForm from '../LoginForm';

import AuthCardLayout from '../../../../layouts/AuthCardLayout';

const Login = () => {
  return (
    <AuthCardLayout
      leftSideContent={
        <Fragment>
          <Fragment>
            <p>
              <br />
            </p>
            <p className="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">FetchRFID</p>
          </Fragment>
        </Fragment>
      }
    >
      <h3>Account Login</h3>
      <LoginForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default Login;
