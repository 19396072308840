import {
  CUSTOMER_ASSET_STATUS_START,
  CCUSTOMER_ASSET_STATUS_FAIL,
  CUSTOMER_ASSET_STATUS_SUCCESS
} from '../types/types';
import { getAssetStatus } from '../../../services/customer/customer';

export const assetStatusStart = () => {
  return {
    type: CUSTOMER_ASSET_STATUS_START,
    payload: { error: null }
  };
};

export const assetStatusSuccess = status => {
  return {
    type: CUSTOMER_ASSET_STATUS_SUCCESS,
    payload: status
  };
};

export const assetStatusFail = error => {
  return {
    type: CCUSTOMER_ASSET_STATUS_FAIL,
    payload: { error }
  };
};

export const assetStatus = token => {
  return dispatch => {
    dispatch(assetStatusStart());

    getAssetStatus(token)
      .then(response => {
        dispatch(assetStatusSuccess(response.data));
      })
      .catch(err => {
        try {
          dispatch(assetStatusFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(assetStatusFail('Please try again later..'));
        }
      });
  };
};
