import { CUSTOMER_STATUS_START, CUSTOMER_STATUS_FAIL, CUSTOMER_STATUS_SUCCESS } from '../types/types';
import * as actions from '../../../services/customer/customer';

export const statusStart = () => {
  return {
    type: CUSTOMER_STATUS_START,
    payload: { error: null }
  };
};

export const statusSuccess = status => {
  return {
    type: CUSTOMER_STATUS_SUCCESS,
    payload: status
  };
};

export const statusFail = error => {
  return {
    type: CUSTOMER_STATUS_FAIL,
    payload: { error }
  };
};

export const status = token => {
  return dispatch => {
    dispatch(statusStart());

    actions
      .getUserStatus(token)
      .then(response => {
        dispatch(statusSuccess(response.data));
      })
      .catch(err => {
        try {
          dispatch(statusFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(statusFail('Please try again later..'));
        }
      });
  };
};
