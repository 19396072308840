import React from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

import CustomerDashboardLayout from './CustomerDashboardLayout';
import SsgDashboardLayout from './SsgDashboardLayout';

import SsgLogin from '../containers/ssg/auth/split/login';
import SsgLogout from '../containers/ssg/auth/split/Logout';
import SsgForgetPassword from '../containers/ssg/auth/split/ForgetPassword';
import SsgPasswordReset from '../containers/ssg/auth/split/PasswordReset';
import SsgConfirmMail from '../containers/ssg/auth/split/ConfirmMail';

import CustomerLogin from '../containers/customer/auth/card/Login';
import CustomerLogout from '../containers/customer/auth/card/Logout';
import CustomerForgetPassword from '../containers/customer/auth/card/ForgetPassword';
import CustomerPasswordReset from '../containers/customer/auth/card/PasswordReset';
import CustomerConfirmMail from '../containers/customer/auth/card/ConfirmMail';

import ErrorLayout from './ErrorLayout';

const Layout = props => {
  const ssgUserType = useSelector(state => state.Ssg.userType);
  const customerUserType = useSelector(state => state.Customer.customerUserType);
  const customerToken = useSelector(state => state.Customer.customerToken);
  const ssgToken = useSelector(state => state.Ssg.token);

  //development
  // const customer = 'cenango';

  //production
  const customer = localStorage.getItem('slug');
  let hostName = window.location.hostname;

  //development
  // hostName = 'admin.fetchrfid.com';
  const hostNameArray = hostName.split('.');
  const adminHost = hostNameArray[0];

  console.log(adminHost);

  return (
    <Router fallback={<span />}>
      <Switch>
        {/*Customer login*/}
        {adminHost !== 'admin' && <Route path="/" exact component={CustomerLogin} />}
        {adminHost !== 'admin' && <Route path="/logout" exact component={CustomerLogout} />}
        {adminHost !== 'admin' && <Route path="/forget-password" exact component={CustomerForgetPassword} />}
        {adminHost !== 'admin' && <Route path="/password-reset/:token" exact component={CustomerPasswordReset} />}
        {adminHost !== 'admin' && <Route path="/confirm-mail" exact component={CustomerConfirmMail} />}
        {adminHost !== 'admin' && customerToken === null && <Redirect to={{ pathname: '/' }} />}

        {/*SSG login*/}
        {/* Development */}
        {adminHost === 'admin' && <Route path="/admin" exact component={SsgLogin} />}

        {adminHost === 'admin' && <Route path="/" exact component={SsgLogin} />}
        {adminHost === 'admin' && <Route path="/admin/logout" exact component={SsgLogout} />}
        {adminHost === 'admin' && <Route path="/admin/forget-password" exact component={SsgForgetPassword} />}
        {adminHost === 'admin' && <Route path="/admin/password-reset/:token" exact component={SsgPasswordReset} />}
        {adminHost === 'admin' && <Route path="/admin/confirm-mail/" exact component={SsgConfirmMail} />}
        {adminHost === 'admin' && ssgToken === null && <Redirect to={{ pathname: '/' }} />}

        {customerUserType === 'customer' && <Route component={CustomerDashboardLayout} />}
        {ssgUserType === 'ssg' && <Route component={SsgDashboardLayout} />}

        {/* {adminHost !== 'admin' && <Redirect to="/" />}
        {adminHost === 'admin' && <Redirect to="/admin" />} */}

        <Route path="/errors" component={ErrorLayout} />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default withRouter(Layout);
