import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';
import { Button, Form, Row, Col, FormGroup, Input, CustomInput, Label } from 'reactstrap';
import withRedirect from '../../../hoc/withRedirect';
import * as actions from '../../../actions/customer/index';

const LoginForm = (props, { setRedirect, hasLabel, layout }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();

  const authError = useSelector(state => state.Customer.authError);
  const isLoggedIn = useSelector(state => state.Customer.customerToken);
  // const loggedUserType = useSelector(state => state.Customer.customerUserType);
  // const authLoading = useSelector(state => state.Customer.authLoading);

  // State
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(true);
  const [slug, setslug] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    dispatch(actions.auth(email, password, remember, slug));

    if (authError === null && isLoggedIn) {
      history.push('/customer');
    }
  };

  useEffect(() => {
    let hostName = window.location.hostname;

    //For Development
    // hostName = 'cenango.fetchrfid.com';

    const slugArray = hostName.split('.');
    const slug = slugArray[0];

    setslug(slug);
    localStorage.setItem('slug', slug);
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      setRedirectToDashboard(true);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setIsDisabled(!email || !password);
  }, [email, password]);

  let from_pathname = '/customer';

  let { from } = location.state || { from: { pathname: from_pathname } };

  if (redirectToDashboard) {
    return <Redirect to={from} />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      {authError && (
        <div className="alert alert-danger" role="alert">
          {authError}
        </div>
      )}
      <FormGroup>
        {hasLabel && <Label>Email address</Label>}
        <Input
          placeholder={!hasLabel ? 'Email address' : ''}
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Password</Label>}
        <Input
          placeholder={!hasLabel ? 'Password' : ''}
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </FormGroup>
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <CustomInput
            id="customCheckRemember"
            label="Remember me"
            checked={remember}
            onChange={({ target }) => setRemember(target.checked)}
            type="checkbox"
          />
        </Col>
        <Col xs="auto">
          <Link className="fs--1" to={'/forget-password'}>
            Forget Password?
          </Link>
        </Col>
      </Row>
      <FormGroup>
        <Button color="primary" block className="mt-3" disabled={isDisabled}>
          Log in
        </Button>
      </FormGroup>
    </Form>
  );
};

LoginForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default withRedirect(LoginForm);
