import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ListGroup from 'reactstrap/es/ListGroup';
import ListGroupItem from 'reactstrap/es/ListGroupItem';
import Notification from '../../../components/notification/Notification';
import classNames from 'classnames';
import { isIterableArray } from '../../../helpers/utils';
import _ from 'lodash';
import newId from '../../../helpers/newId';
// import * as actions from '../../../actions/customer/index';
import { getNotifications } from '../../../services/customer/customer';

const NotificationDropdown = props => {
  const dispatch = useDispatch();

  const token = useSelector(state => state.Customer.customerToken);
  const notifications = useSelector(state => state.Customer.notifications);
  const notificationsLoading = useSelector(state => state.Customer.notificationsLoading);
  const notificationsError = useSelector(state => state.Customer.notificationsError);

  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(false);
  const [unreadcount, setunreadcount] = useState(0);
  const [rawnewnotifications, setrawnewnotifications] = useState([]);
  const [rawearliernotifications, setrawearliernotifications] = useState([]);
  const [error, seterror] = useState([]);
  const [loading, setloading] = useState(false);

  // Handler
  const handleToggle = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // dispatch(actions.notifications(1, 25, token));

    getNotifications(1, 25, token)
      .then(response => {
        const notifications = response.data;

        if (notifications !== undefined && Object.entries(notifications).length > 0) {
          setunreadcount(response.data && notifications.unreads);

          const newRecords = _.take(
            notifications !== null && notifications.records.filter(m => m.status.code === 'UNREAD'),
            2
          );
          const oldRecords = _.take(
            notifications !== null && notifications.records.filter(m => m.status.code === 'READ'),
            1
          );
          setrawnewnotifications(newRecords);
          setrawearliernotifications(oldRecords);
        }
      })
      .catch(err => {
        try {
          setloading(false);
          err.response.data.errors.forEach(er => {
            seterror(prevArray => [...prevArray, er.message]);
          });
        } catch (ex) {
          setloading(false);
          seterror(['Please try again later...']);
        }
      });

    setTimeout(function() {
      setloading(false);
    }, 1000);
  }, [props]);

  // if (notificationsLoading === false) {
  //   setunreadcount(notifications !== null && notifications.unreads);

  //   const newRecords = _.take(
  //     notifications !== null && notifications.records.filter(m => m.status.code === 'UNREAD'),
  //     2
  //   );
  //   const oldRecords = _.take(
  //     notifications !== null && notifications.records.filter(m => m.status.code === 'READ'),
  //     1
  //   );
  //   setrawnewnotifications(newRecords);
  //   setrawearliernotifications(oldRecords);
  // }
  // }, [notifications]);

  return (
    <Dropdown nav inNavbar isOpen={isOpen} toggle={handleToggle}>
      <DropdownToggle
        nav
        className={classNames('px-0', {
          'notification-indicator notification-indicator-primary': !isAllRead
        })}
      >
        {unreadcount > 0 && <span className="notification-indicator-number">{unreadcount}</span>}

        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <Card className="card-notification shadow-none" style={{ maxWidth: '20rem' }}>
          {notificationsError && (
            <div className="alert alert-danger" role="alert" id={newId()}>
              {notificationsError}
            </div>
          )}

          {Object.entries(error).length > 0 &&
            error.map(e => (
              <div className="alert alert-danger" role="alert" id={newId()}>
                {e}
              </div>
            ))}

          <ListGroup flush className="font-weight-normal fs--1">
            <div className="list-group-title">NEW</div>
            {isIterableArray(rawnewnotifications) &&
              rawnewnotifications.map((notification, index) => (
                <ListGroupItem key={index} onClick={handleToggle}>
                  <Notification {...notification} flush />
                </ListGroupItem>
              ))}

            <div className="list-group-title">EARLIER</div>
            {isIterableArray(rawearliernotifications) &&
              rawearliernotifications.map((notification, index) => (
                <ListGroupItem key={index} onClick={handleToggle}>
                  <Notification {...notification} flush />
                </ListGroupItem>
              ))}
          </ListGroup>

          <div className="card-footer text-center border-top-0" onClick={handleToggle}>
            <Link className="card-link d-block" to="/customer/notifications">
              View all
            </Link>
          </div>
        </Card>
      </DropdownMenu>
    </Dropdown>
  );
};

export default NotificationDropdown;
