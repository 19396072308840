import { SSG_SETTINGS_START, SSG_SETTINGS_SUCCESS, SSG_SETTINGS_FAIL } from '../types/types';
import { getPlansSettings } from '../../../services/ssg/ssg';

export const settingsStart = () => {
  return {
    type: SSG_SETTINGS_START,
    payload: { error: null }
  };
};

export const settingsSuccess = status => {
  return {
    type: SSG_SETTINGS_SUCCESS,
    payload: status
  };
};

export const settingsFail = error => {
  return {
    type: SSG_SETTINGS_FAIL,
    payload: { error }
  };
};

export const settings = token => {
  return dispatch => {
    dispatch(settingsStart());

    getPlansSettings(token)
      .then(response => {
        dispatch(settingsSuccess(response.data));
      })
      .catch(err => {
        try {
          dispatch(settingsFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(settingsFail('Please try again later..'));
        }
      });
  };
};
