import { CUSTOMER_ZONE_START, CCUSTOMER_ZONE_FAIL, CUSTOMER_ZONE_SUCCESS } from '../types/types';
import { getZones } from '../../../services/customer/customer';

export const zonesStart = () => {
  return {
    type: CUSTOMER_ZONE_START,
    payload: { error: null }
  };
};

export const zonesSuccess = status => {
  return {
    type: CUSTOMER_ZONE_SUCCESS,
    payload: status
  };
};

export const zonesFail = error => {
  return {
    type: CCUSTOMER_ZONE_FAIL,
    payload: { error }
  };
};

export const zones = token => {
  return dispatch => {
    dispatch(zonesStart());

    getZones(token)
      .then(response => {
        dispatch(zonesSuccess(response.data));
      })
      .catch(err => {
        try {
          dispatch(zonesFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(zonesFail('Please try again later..'));
        }
      });
  };
};
