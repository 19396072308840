import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import withRedirect from '../../../hoc/withRedirect';
import Label from 'reactstrap/es/Label';
import classNames from 'classnames';
import newId from '../../../helpers/newId';
import { resetPassword } from '../../../services/ssg/ssg';
import { Fragment } from 'react';

const PasswordResetForm = ({ setRedirect, setRedirectUrl, layout, hasLabel }) => {
  const { token } = useParams();

  // State
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');
  const [error, seterror] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    seterror([]);

    const resetDetails = {
      token: token,
      email: email,
      password: password
    };

    resetPassword(resetDetails)
      .then(response => {
        toast.success('Login with your new password');
        setRedirect(true);
      })
      .catch(err => {
        try {
          err.response.data.errors.forEach(er => {
            seterror(prevArray => [...prevArray, { id: newId(), message: er.message }]);
          });
        } catch (ex) {
          seterror(['Please try again later...']);
        }
      });
  };

  useEffect(() => {
    setRedirectUrl(`/admin`);
  }, [setRedirectUrl, layout]);

  useEffect(() => {
    if (email === '' || password === '') {
      return setIsDisabled(true);
    } else {
      return setIsDisabled(false);
    }
  }, [email, password]);

  return (
    <Fragment>
      {Object.entries(error).length > 0 &&
        error.map(e => (
          <div className="alert alert-danger" role="alert" key={e.id}>
            {e.message}
          </div>
        ))}
      <Form className={classNames('mt-3', { 'text-left': hasLabel })} onSubmit={handleSubmit}>
        <FormGroup>
          {hasLabel && <Label>Email</Label>}
          <Input
            placeholder={!hasLabel ? 'Email' : ''}
            value={email}
            onChange={({ target }) => setemail(target.value)}
            type="email"
          />
        </FormGroup>
        <FormGroup>
          {hasLabel && <Label>New Password</Label>}
          <Input
            placeholder={!hasLabel ? 'New Password' : ''}
            value={password}
            onChange={({ target }) => setpassword(target.value)}
            type="password"
          />
        </FormGroup>
        <Button color="primary" block className="mt-3" disabled={isDisabled}>
          Set password
        </Button>
      </Form>
    </Fragment>
  );
};

PasswordResetForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  setRedirectUrl: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

PasswordResetForm.defaultProps = { layout: 'basic', hasLabel: false };

export default withRedirect(PasswordResetForm);
