import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from '../src/common/redux/store';
import Layout from './layouts/Layout';
import Boot from './common/redux/boot';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';

const App = () => (
  <Provider store={store}>
    <Router basename={process.env.PUBLIC_URL}>
      <Layout />
    </Router>
  </Provider>
);

Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
