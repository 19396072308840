import { SSG_TIMEZONE_START, SSG_TIMEZONE_SUCCESS, SSG_TIMEZONE_FAIL } from '../types/types';
import { getTimeZones } from '../../../services/ssg/ssg';

export const timeStart = () => {
  return {
    type: SSG_TIMEZONE_START,
    payload: { error: null }
  };
};

export const timeSuccess = status => {
  return {
    type: SSG_TIMEZONE_SUCCESS,
    payload: status
  };
};

export const timeFail = error => {
  return {
    type: SSG_TIMEZONE_FAIL,
    payload: { error }
  };
};

export const timeZones = token => {
  return dispatch => {
    dispatch(timeStart());

    getTimeZones(token)
      .then(response => {
        dispatch(timeSuccess(response.data));
      })
      .catch(err => {
        try {
          dispatch(timeFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(timeFail('Please try again later..'));
        }
      });
  };
};
