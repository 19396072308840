import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
// import Dashboard from '../components/dashboard/Dashboard';
// import DashboardAlt from '../components/dashboard-alt/DashboardAlt';
import NavbarTop from '../containers/ssg/navbar/NavbarTop';
import NavbarVertical from '../containers/ssg/navbar/SsgNavbarVertical';
import Footer from '../components/footer/Footer';
import loadable from '@loadable/component';
import AppContext from '../context/Context';

import DashboardAlt from '../containers/ssg/dashboard/DashboardAlt';

const SsgDashboardRoutes = loadable(() => import('../containers/ssg/routes/SsgDashboardRoutes'));

const SsgDashboardLayout = ({ location }) => {
  const { isFluid } = useContext(AppContext);

  useEffect(() => {
    SsgDashboardRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      <NavbarVertical />

      <div className="content">
        <NavbarTop />
        <Switch>
          <Route path="/ssg" exact component={DashboardAlt} />
          <SsgDashboardRoutes />
        </Switch>
        <Footer />
      </div>
    </div>
  );
};

SsgDashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default SsgDashboardLayout;
