import { CUSTOMER_GROUP_START, CUSTOMER_GROUP_SUCCESS, CUSTOMER_GROUP_FAIL } from '../types/types';
import * as actions from '../../../services/customer/customer';

export const groupStart = () => {
  return {
    type: CUSTOMER_GROUP_START,
    payload: { error: null }
  };
};

export const groupsSuccess = status => {
  return {
    type: CUSTOMER_GROUP_SUCCESS,
    payload: status
  };
};

export const groupsFail = error => {
  return {
    type: CUSTOMER_GROUP_FAIL,
    payload: { error }
  };
};

export const groups = token => {
  return dispatch => {
    dispatch(groupStart());

    actions
      .getUserGroups(token)
      .then(response => {
        dispatch(groupsSuccess(response.data));
      })
      .catch(err => {
        try {
          dispatch(groupsFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(groupsFail('Please try again later..'));
        }
      });
  };
};
