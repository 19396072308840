import React from 'react';
import PropTypes from 'prop-types';
import Media from 'reactstrap/es/Media';
import Avatar from '../../../components/common/Avatar';
import defaultAvatar from '../../../assets/img/team/avatar.png';
import { Link } from 'react-router-dom';

const ActiveUser = ({ first_name, last_name, userGroup }) => (
  <Media className="align-items-center mb-3">
    <Avatar src={defaultAvatar} />
    <Media body className="ml-3">
      <h6 className="mb-0 font-weight-semi-bold">
        <Link className="text-900" to="/pages/profile">
          {first_name} {last_name}
        </Link>
      </h6>
      <p className="text-500 fs--2 mb-0">{userGroup.name}</p>
    </Media>
  </Media>
);

ActiveUser.propTypes = {
  first_name: PropTypes.string.isRequired,
  userGroup: PropTypes.object.isRequired
};

export default ActiveUser;
