import React from 'react';
import LogoutContent from '../LogoutContent';

import AuthCardLayout from '../../../../layouts/AuthCardLayout';

const Logout = () => {
  return (
    <AuthCardLayout
      leftSideContent={<p className="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">FetchRFID</p>}
    >
      <div className="text-center">
        <LogoutContent layout="card" titleTag="h3" />
      </div>
    </AuthCardLayout>
  );
};

export default Logout;
