import React from 'react';
import classNames from 'classnames';
import { Media, Row, Col } from 'reactstrap';
import Flex from '../../../components/common/Flex';
import _ from 'lodash';
import moment from 'moment';

const colorArray = ['warning', 'info', 'primary', 'danger', 'success'];

const RecentTenant = ({ tenant, isLast }) => {
  const { company_name, added_time, userPlan } = tenant;

  let color = _.sample(colorArray);

  return (
    <Row className={classNames('align-items-center py-2', { 'border-bottom border-200 ': !isLast })}>
      <Col className="py-1">
        <Media className="align-items-center">
          <div className="avatar avatar-xl mr-3">
            <div className={`avatar-name rounded-circle bg-soft-${color}`}>
              <span className={`fs-0 text-${color}`}>{company_name[0]}</span>
            </div>
          </div>
          <Media body className="position-relative">
            <Flex tag="h6" align="center" className="mb-0">
              <a className="text-800 stretched-link" href="#!">
                {company_name}
              </a>
            </Flex>
          </Media>
        </Media>
      </Col>
      <Col>
        <Row className="justify-content-end align-items-center">
          <Col xs="auto pr-0">
            <div className="fs--1 font-weight-semi-bold">{userPlan.name}</div>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row className="justify-content-end align-items-center">
          <Col xs="auto pr-0">
            <div className="fs--1 font-weight-semi-bold">
              {added_time === null ? '' : moment(added_time).format('MM/DD/YYYY')}
            </div>
          </Col>
          <Col xs="5" className="pr-card" />
        </Row>
      </Col>
    </Row>
  );
};

RecentTenant.defaultProps = { isLast: false };

export default RecentTenant;
