import { SSG_AUTH_SUCCESS } from '../../actions/ssg/types/types';
import { CUSTOMER_AUTH_SUCCESS } from '../../actions/customer/types/types';

export const checkAuthorization = () => {
  return dispatch => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const userType = localStorage.getItem('userType');
    const avatar = localStorage.getItem('avatar');

    const customerToken = localStorage.getItem('customerToken');
    const customerUserId = localStorage.getItem('customerUserId');
    const customerUserType = localStorage.getItem('customerUserType');
    const customerAvatar = localStorage.getItem('customerAvatar');

    if (userType && userId && token) {
      if (userType === 'ssg') {
        dispatch({
          type: SSG_AUTH_SUCCESS,
          payload: { token, userId, userType, avatar }
        });
      }
    }

    if (customerUserType && customerUserId && customerToken) {
      if (customerUserType === 'customer') {
        dispatch({
          type: CUSTOMER_AUTH_SUCCESS,
          payload: { customerToken, customerUserId, customerUserType, customerAvatar }
        });
      }
    }
  };
};
