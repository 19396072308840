import { CUSTOMER_ROLE_START, CUSTOMER_ROLE_SUCCESS, CUSTOMER_ROLE_FAIL } from '../types/types';
import * as actions from '../../../services/customer/customer';

export const rolsStart = () => {
  return {
    type: CUSTOMER_ROLE_START,
    payload: { error: null }
  };
};

export const rolsSuccess = status => {
  return {
    type: CUSTOMER_ROLE_SUCCESS,
    payload: status
  };
};

export const rolsFail = error => {
  return {
    type: CUSTOMER_ROLE_FAIL,
    payload: { error }
  };
};

export const role = token => {
  return dispatch => {
    dispatch(rolsStart());

    actions
      .getRoles(token)
      .then(response => {
        dispatch(rolsSuccess(response.data));
      })
      .catch(err => {
        console.log(err.reponse);
        try {
          dispatch(rolsFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(rolsFail('Please try again later..'));
        }
      });
  };
};
