import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { geDashboardData } from '../../../services/ssg/ssg';
import Loader from '../../../components/common/Loader';
import _ from 'lodash';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import newId from '../../../helpers/newId';

import WeeklyTenants from './WeeklyTenants';
import TotalTenants from './TotalTenants';
import TenantByPlanRenew from './TenantByPlanRenew';
import TenantByPlan from './TenantByPlan';

import UpcomingRenewals from './UpcomingRenewals';
import TenantRegistrationTrend from './TenantRegistrationTrend';

import ActiveUsers from './ActiveUsers';
import RecentTenants from './RecentTenants';

//TODO chnage data to reald data
import marketShare from '../../../data/dashboard/marketShare';
import products from '../../../data/dashboard/products';

import loadable from '@loadable/component';
const TenantsMap = loadable(() => import('./TenantsMap'));

const DashboardAlt = () => {
  let history = useHistory();
  const token = useSelector(state => state.Ssg.token);

  // State
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState([]);
  const [totalweeklytenants, settotalweeklytenants] = useState([]);
  const [totaltenants, settotaltenants] = useState([]);

  const [tenantbyplans, settenantbyplans] = useState([]);
  const [tenantbyplansrenew, settenantbyplansrenew] = useState([]);
  const [users, setusers] = useState([]);
  const [tenantregistrationtrend, settenantregistrationtrend] = useState({});

  const [recenttenants, setrecenttenants] = useState([]);
  const [tenantsmap, settenantsmap] = useState([]);

  useEffect(() => {
    seterror([]);
    setloading(true);

    geDashboardData(token)
      .then(response => {
        //New Registration for This Week
        let totalWeeklyTenants = [];
        totalWeeklyTenants.splice(1, 0, 0);
        totalWeeklyTenants.splice(2, 0, 0);
        totalWeeklyTenants.splice(3, 0, 0);
        totalWeeklyTenants.splice(4, 0, 0);
        totalWeeklyTenants.splice(5, 0, 0);
        totalWeeklyTenants.splice(6, 0, 0);
        totalWeeklyTenants.splice(7, 0, 0);

        response.data.total_this_week_tenants.forEach(e => {
          let day = moment(e.date).isoWeekday();

          switch (day) {
            case 1:
              totalWeeklyTenants.splice(1, 1);
              totalWeeklyTenants.splice(1, 0, e.total);
              break;

            case 2:
              totalWeeklyTenants.splice(2, 1);
              totalWeeklyTenants.splice(2, 0, e.total);
              break;

            case 3:
              totalWeeklyTenants.splice(3, 1);
              totalWeeklyTenants.splice(3, 0, e.total);
              break;

            case 4:
              totalWeeklyTenants.splice(4, 1);
              totalWeeklyTenants.splice(4, 0, e.total);
              break;

            case 5:
              totalWeeklyTenants.splice(5, 1);
              totalWeeklyTenants.splice(5, 0, e.total);
              break;

            case 6:
              totalWeeklyTenants.splice(6, 1);
              totalWeeklyTenants.splice(6, 0, e.total);
              break;

            case 7:
              totalWeeklyTenants.splice(7, 1);
              totalWeeklyTenants.splice(7, 0, e.total);
              break;

            default:
              break;
          }
        });

        let first = totalWeeklyTenants[0];
        let updatedWeeklyTenants = _.drop([...totalWeeklyTenants, first]);
        settotalweeklytenants(updatedWeeklyTenants);

        //Total Tenants
        const totalTenants =
          Object.entries(response.data.total_tenants).length > 0 && response.data.total_tenants.map(t => t.total);
        settotaltenants(totalTenants);

        //Tenant by Plan
        let plans = [];
        response.data.tenant_by_plans.forEach(e => {
          switch (e.plan.name.trim()) {
            case 'Bronze Basic':
              plans.push({ id: 1, value: e.total, name: 'Bronze', color: '#E74C3C' });
              break;

            case 'Silver Basic':
              plans.push({ id: 2, value: e.total, name: 'Silver', color: '#ECF0F1' });
              break;

            case 'Gold Basic':
              plans.push({ id: 3, value: e.total, name: 'Gold', color: '#F7DC6F' });
              break;

            case 'Bronze Option':
              plans.push({ id: 4, value: e.total, name: 'Br. Option', color: '#B03A2E' });
              break;

            case 'Silver Option':
              plans.push({ id: 5, value: e.total, name: 'Si. Option', color: '#D0D3D4' });
              break;

            case 'Gold Option':
              plans.push({ id: 6, value: e.total, name: 'Go. Option', color: '#F1C40F' });
              break;

            default:
              break;
          }
        });

        //Tenant by Plan
        settenantbyplans(plans);

        //SSG Users
        setusers(response.data.total_users);

        //Tenant Registration Trend
        let tenantRegTred = [];

        for (let index = 2; index < 34; index++) {
          tenantRegTred.splice(index, 0, 0);
        }

        let month = '';
        response.data.tenant_registration_trend.forEach(e => {
          month = moment(e.date).format('MMMM');
          let date = moment(e.date).format('DD');
          tenantRegTred.splice(date, 1);
          tenantRegTred.splice(date, 0, e.total);
        });

        const totalTenantsByMonth = {
          [_.toLower(month)]: tenantRegTred
        };
        settenantregistrationtrend(totalTenantsByMonth);

        //Recent Tenants
        setrecenttenants(response.data.recent_tenants);

        //Tenants Map
        let tenantsMap = [];
        response.data.clients_by_countries.forEach(e => {
          if (e.country.sortname === 'US') {
            let cordinates = {
              lat: parseFloat(e.state.latitude),
              long: parseFloat(e.state.longitude),
              name: e.state.name,
              street: ' ',
              location: ' '
            };
            tenantsMap.push(cordinates);
          } else {
            let cordinates = {
              lat: parseFloat(e.country.latitude),
              long: parseFloat(e.country.longitude),
              name: e.country.name,
              street: ' ',
              location: ' '
            };
            tenantsMap.push(cordinates);
          }
        });

        settenantsmap(tenantsMap);
      })
      .catch(err => {
        try {
          setloading(false);
          err.response.data.errors.forEach(er => {
            seterror(prevArray => [...prevArray, er.message]);
          });
        } catch (ex) {
          setloading(false);
          seterror(['Please try again later...']);
        }
      });

    setTimeout(function() {
      setloading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (token === null) {
      history.push('/admin');
    }
  }, []);

  return (
    <Fragment>
      {Object.entries(error).length > 0 &&
        error.map(e => (
          <div className="alert alert-danger" role="alert" id={newId()}>
            {e}
          </div>
        ))}

      {loading === true ? (
        <Loader />
      ) : (
        <Fragment>
          <Row noGutters>
            <Col md={3} className="col-xxl-3 mb-3 pr-md-2">
              <WeeklyTenants data={totalweeklytenants} />
            </Col>

            <Col md={3} className="col-xxl-3 mb-3 pr-md-2">
              <TotalTenants data={totaltenants} />
            </Col>

            <Col md={3} className="col-xxl-3 mb-3 pr-md-2">
              <TenantByPlanRenew data={marketShare} />
            </Col>

            <Col md={3} className="col-xxl-3 mb-3 pr-md-2">
              <TenantByPlan data={tenantbyplans !== [] && tenantbyplans} />
            </Col>
          </Row>

          <Row noGutters>
            <Col lg={6} className="mb-3 pr-lg-2 mb-3">
              <UpcomingRenewals renewals={products} />
            </Col>

            <Col lg={6} className="mb-3 pl-lg-2 mb-3">
              <TenantRegistrationTrend data={tenantregistrationtrend} />
            </Col>
          </Row>

          <Row noGutters>
            <Col sm={4} className="col-xxl-4 pr-sm-2 mb-3 mb-xxl-0">
              <ActiveUsers users={users} />
            </Col>

            <Col lg={8} className="col-xxl-8 pr-sm-2 mb-3 mb-xxl-0">
              <RecentTenants tenants={recenttenants} />
            </Col>
          </Row>

          <Row noGutters>
            <Col lg={12} className="col-xxl-12 pr-sm-2 mb-3 mb-xxl-0" style={{ paddingTop: '18px' }}>
              <TenantsMap tenants={tenantsmap} />
            </Col>
          </Row>

          <Row noGutters />
        </Fragment>
      )}
    </Fragment>
  );
};

export default DashboardAlt;
