import { CUSTOMER_LOCATIONS_START, CUSTOMER_LOCATIONS_SUCCESS, CUSTOMER_LOCATIONS_FAIL } from '../types/types';
import { getLocations } from '../../../services/customer/customer';

export const locationStart = () => {
  return {
    type: CUSTOMER_LOCATIONS_START,
    payload: { error: null }
  };
};

export const locationSuccess = status => {
  return {
    type: CUSTOMER_LOCATIONS_SUCCESS,
    payload: status
  };
};

export const locationFail = error => {
  return {
    type: CUSTOMER_LOCATIONS_FAIL,
    payload: { error }
  };
};

export const locations = token => {
  return dispatch => {
    dispatch(locationStart());
    getLocations(token)
      .then(response => {
        dispatch(locationSuccess(response.data));
      })
      .catch(err => {
        console.log(err.reponse);
        try {
          dispatch(locationFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(locationFail('Please try again later..'));
        }
      });
  };
};
