export default {
  /**
   * Development
   * Customer login-form js Change Development Slug to cenango
   * loayout.js change admin login route to path="/admin"
   */
  // apiUrl: 'http://52.86.118.27:8080/api/',

  // apiUrl_Customer: 'http://52.86.118.27:8081/api/',

  /**
   * Production
   */
  apiUrl: 'https://vza1.fetchrfid.com/api',

  apiUrl_Customer: 'https://vz1.fetchrfid.com/api/',

  google: {
    analyticsKey: 'UA-xxxxxxxxx-1'
  }
};
