import {
  CUSTOMER_CATEGORY_FIELD_STATUS_START,
  CCUSTOMER_CATEGORY_FIELD_STATUS_FAIL,
  CUSTOMER_CATEGORY_FIELD_STATUS_SUCCESS
} from '../types/types';
import { getCategoryFieldStatus } from '../../../services/customer/customer';

export const categoryFieldStatusStart = () => {
  return {
    type: CUSTOMER_CATEGORY_FIELD_STATUS_START,
    payload: { error: null }
  };
};

export const categoryFieldStatusSuccess = status => {
  return {
    type: CUSTOMER_CATEGORY_FIELD_STATUS_SUCCESS,
    payload: status
  };
};

export const categoryFieldStatusFail = error => {
  return {
    type: CCUSTOMER_CATEGORY_FIELD_STATUS_FAIL,
    payload: { error }
  };
};

export const categoryFieldStatus = token => {
  return dispatch => {
    dispatch(categoryFieldStatusStart());

    getCategoryFieldStatus(token)
      .then(response => {
        dispatch(categoryFieldStatusSuccess(response.data));
      })
      .catch(err => {
        try {
          dispatch(categoryFieldStatusFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(categoryFieldStatusFail('Please try again later..'));
        }
      });
  };
};
