export const homeRoutes = {
  name: 'Dashboard',
  to: '/ssg',
  exact: true,
  icon: 'chart-pie'
};

export const ssgUsersRoutes = {
  name: 'SSG Users',
  to: '/ssgusers',
  icon: 'users',
  children: [{ to: '/ssg/ssgusers/groups', name: 'Groups' }, { to: '/ssg/ssgusers/users', name: 'Users' }]
};

export const tenantsRoutes = {
  name: 'Tenants',
  to: '/clients',
  icon: 'users',
  children: [
    { to: '/ssg/tenants/tenants', name: 'Tenants' },
    { to: '/ssg/tenants/superadmins', name: 'Super Admins' },
    { to: '/ssg/tenants/devices', name: 'Devices' }
  ]
};

export const planRoutes = {
  name: 'Plans',
  to: '/ssg/plans',
  icon: 'puzzle-piece',
  exact: true
};

export const reportsRoutes = {
  name: 'Reports',
  to: '/ssg/reports',
  icon: 'stream',
  children: [{ to: '/ssg/planbasedreports/', name: 'Plan Based Report' }]
};

export default [homeRoutes, ssgUsersRoutes, planRoutes, tenantsRoutes, reportsRoutes];
