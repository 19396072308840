/*AUTH TYPES*/
export const SSG_AUTH_START = 'SSG_AUTH_START';
export const SSG_AUTH_FAIL = 'SSG_AUTH_FAIL';
export const SSG_AUTH_SUCCESS = 'SSG_AUTH_SUCCESS';
export const SSG_AUTH_OUT = 'SSG_AUTH_OUT';
export const SSG_CHECK_AUTHORIZATION = 'SSG_CHECK_AUTHORIZATION';

export const SSG_CONFIRM_MAIL = 'SSG_CONFIRM_MAIL';

export const SSG_STATUS_START = 'SSG_STATUS_START';
export const SSG_STATUS_FAIL = 'SSG_STATUS_FAIL';
export const SSG_STATUS_SUCCESS = 'SSG_STATUS_SUCCESS';

export const SSG_ROLE_START = 'SSG_ROLE_START';
export const SSG_ROLE_FAIL = 'SSG_ROLE_FAIL';
export const SSG_ROLE_SUCCESS = 'SSG_ROLE_SUCCESS';

export const SSG_PLAN_START = 'SSG_PLAN_START';
export const SSG_PLAN_SUCCESS = 'SSG_PLAN_SUCCESS';
export const SSG_PLAN_FAIL = 'SSG_PLAN_FAIL';

export const SSG_SETTINGS_START = 'SSG_SETTINGS_START';
export const SSG_SETTINGS_SUCCESS = 'SSG_SETTINGS_SUCCESS';
export const SSG_SETTINGS_FAIL = 'SSG_SETTINGS_FAIL';

export const SSG_COUNTRIES_START = 'SSG_COUNTRIES_START';
export const SSG_COUNTRIES_SUCCESS = 'SSG_COUNTRIES_SUCCESS';
export const SSG_COUNTRIES_FAIL = 'SSG_COUNTRIES_FAIL';

export const SSG_STATES_START = 'SSG_STATES_START';
export const SSG_STATES_SUCCESS = 'SSG_STATES_SUCCESS';
export const SSG_STATES_FAIL = 'SSG_STATES_FAIL';

export const SSG_TIMEZONE_START = 'SSG_TIMEZONE_START';
export const SSG_TIMEZONE_SUCCESS = 'SSG_TIMEZONE_SUCCESS';
export const SSG_TIMEZONE_FAIL = 'SSG_TIMEZONE_FAIL';
