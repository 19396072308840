import React from 'react';
import { Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import RecentTenant from './RecentTenant';

const RecentTenants = ({ tenants }) => (
  <Card className="h-lg-100">
    <FalconCardHeader title="Recent Tenants" titleTag="h6" />
    <CardBody className="py-0">
      {tenants.map((tenant, index) => (
        <RecentTenant tenant={tenant} isLast={index === tenants.length - 1} key={tenant.id} />
      ))}
    </CardBody>
  </Card>
);

export default RecentTenants;
