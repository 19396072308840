import React from 'react';
import PropTypes from 'prop-types';
import { isIterableArray } from '../../../helpers/utils';
import TenentByPlanItem from './TenantByPlanItem';
import { Card, CardBody, Col, Row } from 'reactstrap';
import 'echarts/lib/chart/pie';
import FalconCardHeader from '../../../components/common/FalconCardHeader';

const TenantByPlanRenew = ({ data }) => {
  return (
    <Card className="h-md-100">
      <FalconCardHeader className="pb-0" title="Upcoming Renewals by Plan" light={false} titleTag="h6" />
      <CardBody>
        <Row noGutters className="h-100 justify-content-between">
          <Col xs={5} sm={6} className="col-xxl pr-2">
            <div className="fs--2 mt-3">
              {isIterableArray(data) &&
                data.slice(0, 3).map(({ id, ...rest }) => <TenentByPlanItem {...rest} key={id} />)}
            </div>
          </Col>
          <Col xs={5} sm={6} className="col-xxl pr-2">
            <div className="fs--2 mt-3">
              {isIterableArray(data) &&
                data.slice(3, 6).map(({ id, ...rest }) => <TenentByPlanItem {...rest} key={id} />)}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

TenantByPlanRenew.propTypes = { data: PropTypes.array.isRequired };

export default TenantByPlanRenew;
