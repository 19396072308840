import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import Background from '../../../components/common/Background';
import corner1 from '../../../assets/img/illustrations/corner-1.png';
import corner2 from '../../../assets/img/illustrations/corner-2.png';
import corner3 from '../../../assets/img/illustrations/corner-3.png';

const getImage = color => {
  switch (color) {
    case 'warning':
      return corner1;
    case 'info':
      return corner2;
    case 'success':
      return corner3;
    default:
      return corner1;
  }
};

const CardSummary = ({ title, color, handHelds, users, reports, readers }) => {
  if (title === null) title = '';
  if (handHelds === null) handHelds = 0;
  if (users == null) users = 0;
  if (users === null) handHelds = 0;
  if (readers == null) users = 0;
  if (color == null) color = 'primary';

  return (
    <Card className="mb-3 overflow-hidden" style={{ minWidth: '12rem' }}>
      <Background image={getImage(color)} className="bg-card" />
      <CardBody className="mb-1">
        <h6>{title}</h6>
        <Row>
          <Col>
            <Row>
              <Col>
                <span className="font-weight-semi-bold" style={{ fontSize: '9px' }}>
                  {'H.Helds'}
                </span>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingLeft: '1px' }}>
                <span className={`badge badge-soft-${color} rounded-capsule ml-2`}>{handHelds}</span>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row>
              <Col>
                <span className="font-weight-semi-bold" style={{ fontSize: '9px' }}>
                  {'Readers'}
                </span>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingLeft: '1px' }}>
                <span className={`badge badge-soft-${color} rounded-capsule ml-2`}>{readers}</span>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row>
              <Col>
                <span className="font-weight-semi-bold" style={{ fontSize: '9px' }}>
                  {'Users'}
                </span>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingLeft: '1px' }}>
                <span className={`badge badge-soft-${color} rounded-capsule ml-2`}>{users}</span>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row>
              <Col>
                <span className="font-weight-semi-bold" style={{ fontSize: '9px' }}>
                  {'Reports'}
                </span>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingLeft: '1px' }}>
                <span className={`badge badge-soft-${color} rounded-capsule ml-2`}>{reports}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

CardSummary.propTypes = {
  title: PropTypes.string.isRequired,
  handHelds: PropTypes.number,
  users: PropTypes.number,
  reports: PropTypes.number,
  readers: PropTypes.number,
  color: PropTypes.string
};

CardSummary.defaultProps = {
  linkText: 'See all',
  to: '#!',
  color: 'primary'
};

export default CardSummary;
