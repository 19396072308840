import React from 'react';
import ConfirmMailContent from '../ConfirmMailContent';
import AuthCardLayout from '../../../../layouts/AuthCardLayout';

const ConfirmMail = () => (
  <AuthCardLayout leftSideContent={<p className="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">FetchRFID</p>}>
    <div className="text-center">
      <ConfirmMailContent layout="card" titleTag="h3" />
    </div>
  </AuthCardLayout>
);

export default ConfirmMail;
