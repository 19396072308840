import React from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import Avatar from '../../../components/common/Avatar';
import team3 from '../../../assets/img/team/avatar.png';

const ProfileDropdown = () => (
  <UncontrolledDropdown nav inNavbar>
    <DropdownToggle nav className="pr-0">
      <Avatar src={team3} />
    </DropdownToggle>
    <DropdownMenu right className="dropdown-menu-card">
      <div className="bg-white rounded-soft py-2">
        <DropdownItem tag={Link} to="/customer/usermanagement/profile">
          Profile
        </DropdownItem>

        <DropdownItem tag={Link} to="/customer/usermanagement/profile/logs">
          Logs
        </DropdownItem>

        <DropdownItem tag={Link} to="/logout">
          Logout
        </DropdownItem>
      </div>
    </DropdownMenu>
  </UncontrolledDropdown>
);

export default ProfileDropdown;
