import { CUSTOMER_CATEGORY_START, CUSTOMER_CATEGORY_SUCCESS, CUSTOMER_CATEGORY_FAIL } from '../types/types';
import { getCategories } from '../../../services/customer/customer';

export const categoryStart = () => {
  return {
    type: CUSTOMER_CATEGORY_START,
    payload: { error: null }
  };
};

export const categorySuccess = categories => {
  return {
    type: CUSTOMER_CATEGORY_SUCCESS,
    payload: categories
  };
};

export const categoryFail = error => {
  return {
    type: CUSTOMER_CATEGORY_FAIL,
    payload: { error }
  };
};

export const categories = token => {
  return dispatch => {
    dispatch(categoryStart());

    getCategories(token)
      .then(response => {
        dispatch(categorySuccess(response.data));
      })
      .catch(err => {
        console.log(err.reponse);
        try {
          dispatch(categoryFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(categoryFail('Please try again later..'));
        }
      });
  };
};
