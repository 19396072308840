import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import UpcomingRenewal from './UpcomingRenewal';

const UpcomingRenewals = ({ renewals }) => (
  <Card className="h-lg-100">
    <FalconCardHeader title="Upcoming Renewals" titleTag="h6" />
    <CardBody className="py-0">
      {renewals.map((renewal, index) => (
        <UpcomingRenewal renewal={renewal} isLast={index === renewals.length - 1} key={renewal.id} />
      ))}
    </CardBody>
  </Card>
);

UpcomingRenewals.propTypes = { renewals: PropTypes.arrayOf(UpcomingRenewal.propTypes.renewal).isRequired };

export default UpcomingRenewals;
