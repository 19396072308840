import API from '../../helpers/apiCustomer';

//Auth
export const signIn = authData => {
  let config = {
    headers: { 'x-ssg-tenant-id': localStorage.getItem('slug') }
  };

  return API.post('auth/signin', authData, config);
};

export const forgotPassword = email => {
  let config = {
    headers: { 'x-ssg-tenant-id': localStorage.getItem('slug') }
  };

  return API.post('/auth/forgot-password', email, config);
};

export const resetPassword = resetDetails => {
  let config = {
    headers: { 'x-ssg-tenant-id': localStorage.getItem('slug') }
  };

  return API.post('/auth/reset-password', resetDetails, config);
};

export const getNewToken = refreshToken => {
  let config = {
    headers: { 'x-ssg-tenant-id': localStorage.getItem('slug') }
  };

  return API.post('/auth/token', refreshToken, config);
};

//Users
export const getUsers = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('users', config);
};

export const creatNewUser = (userInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.post('users', userInfor, config);
};

export const updateUser = (userid, userInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.put('users/' + userid, userInfor, config);
};

export const deleteUser = (userid, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.delete('users/' + userid, config);
};

export const getUserLogs = (userId, page, size, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    },
    params: { page: page, limit: size }
  };

  return API.get('/users/' + userId + '/logs', config);
};

//Groups
export const getUserGroups = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('groups', config);
};

export const getUserGroup = (groupid, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('groups/' + groupid, config);
};

export const createNewGroup = (groupInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.post('groups', groupInfor, config);
};

export const updateGroup = (groupId, groupInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.put('groups/' + groupId, groupInfor, config);
};

export const deleteGroup = (groupId, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.delete('groups/' + groupId, config);
};

//Roles
export const getRoles = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('users/roles', config);
};

//Status
export const getUserStatus = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('status/user', config);
};

export const getLocationStatus = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('status/location', config);
};

export const getCategoryStatus = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('status/category', config);
};

export const getCategoryFieldStatus = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('status/category-fields', config);
};

export const getAssetStatus = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('status/asset', config);
};

export const getContainerStatus = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('status/container', config);
};

export const getZoneStatus = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('status/zone', config);
};

//User profile
export const getUserProfile = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('me', config);
};

export const updateProfile = (userInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.put('me', userInfor, config);
};

export const getLogs = (page, size, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    },
    params: { page: page, limit: size }
  };

  return API.get('/me/logs', config);
};

export const signOff = (refreshToken, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };
  return API.post('/me/logout', refreshToken, config);
};

//Categories

export const getCategories = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('/categories', config);
};

export const createNewCategory = (categoryInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.post('categories', categoryInfor, config);
};

export const updateCategory = (categoryId, categoryInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.put('categories/' + categoryId, categoryInfor, config);
};

export const deleteCategory = (categoryId, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.delete('categories/' + categoryId, config);
};

//Locations

export const getLocations = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('locations', config);
};

export const createNewLocation = (locationInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.post('locations', locationInfor, config);
};

export const updateLocation = (locationId, locationInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.put('locations/' + locationId, locationInfor, config);
};

export const deleteLocation = (locationId, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.delete('locations/' + locationId, config);
};

//Category Fields

export const getCategoryFields = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('/categories/fields', config);
};

export const createNewCategoryField = (fieldInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.post('/categories/fields', fieldInfor, config);
};

export const updateCategoryField = (fieldId, fieldInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.put('/categories/fields/' + fieldId, fieldInfor, config);
};

export const deleteCategoryField = (fieldId, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.delete('/categories/fields/' + fieldId, config);
};

//Containers
export const getContainers = (page, size, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    },
    params: { page: page, limit: size }
  };
  return API.get('containers/', config);
};

export const getContainersBySearch = (name, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    },
    params: { name: name }
  };

  return API.get('/containers/search/parent', config);
};

export const createNewContainer = async (containerInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.post('containers', containerInfor, config);
};

export const updateContainer = (containerId, containerInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.put('containers/' + containerId, containerInfor, config);
};

export const getContainer = (containerId, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('containers/' + containerId, config);
};

export const deleteContainer = (containerId, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.delete('containers/' + containerId, config);
};

export const getContainerAssets = (containerId, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };
  return API.get('containers/' + containerId + '/assets', config);
};

//Container Fields

export const getContainerFields = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('/containers/fields', config);
};

export const createNewtContainerField = (fieldInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.post('/containers/fields', fieldInfor, config);
};

export const updateContainersField = (fieldId, fieldInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.put('/containers/fields/' + fieldId, fieldInfor, config);
};

export const deleteContainersField = (fieldId, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.delete('/containers/fields/' + fieldId, config);
};

//Asset Fields
export const getAssetFields = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('/assets/fields', config);
};

export const createNewtAssetField = (fieldInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.post('/assets/fields', fieldInfor, config);
};

export const updateAssetField = (fieldId, fieldInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.put('/assets/fields/' + fieldId, fieldInfor, config);
};

export const deleteAssetField = (fieldId, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.delete('/assets/fields/' + fieldId, config);
};

//Assets
export const getAssets = (page, size, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    },
    params: { page: page, limit: size }
  };

  return API.get('/assets', config);
};

export const getAssetLogs = (assetId, page, size, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    },
    params: { page: page, limit: size }
  };

  return API.get('/assets/' + assetId + '/logs', config);
};

export const getAsset = (assetId, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('/assets/' + assetId, config);
};

export const createNewtAsset = async (assetInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.post('/assets', assetInfor, config);
};

export const updateAsset = (assetId, assetInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.put('/assets/' + assetId, assetInfor, config);
};

export const deleteAsset = (assetId, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.delete('/assets/' + assetId, config);
};

//Assets Search
export const serchAssets = (q, category, location, page, limit, token) => {
  let category_id = [];
  category_id = [...category_id, category];

  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    },
    params: { q: q, category_id: category_id, location_id: location, page: page, limit: limit }
  };

  return API.get('/assets/search', config);
};

//Asset image
export const uploadAssetImage = (assetId, token, blob) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };
  var file = new File([blob], blob.name, { lastModified: new Date(), type: 'image/jpeg' });

  const data = new FormData();
  data.append('image', file);

  return API.put('assets/' + assetId + '/image', data, config);
};

//asset template
export const getAssetTemplate = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('assets/template', config);
};

//asset template data upload
export const uploadAssetTemplateData = (assets, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.post('assets/import', assets, config);
};

//Notifications
export const getNotifications = (page, limit, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    },
    params: { page: page, limit: limit }
  };

  return API.get('/notifications', config);
};

export const getUnAuthNotifications = (page, limit, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    },
    params: { page: page, limit: limit }
  };

  return API.get('/notifications/unauth-access', config);
};

export const makeAsReadNotification = (id, token) => {
  console.log('id', id);
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.post('/notifications/' + id + '/read', {}, config);
};

export const deleteNotification = (id, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.delete('/notifications/' + id, config);
};

//Devices

export const getDevices = (page, limit, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    },
    params: { page: page, limit: limit }
  };

  return API.get('/devices', config);
};

export const searchDevices = (typeId, text, page, limit, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    },
    params: { typeid: typeId, text: text, page: page, limit: limit }
  };

  return API.get('/devices/search', config);
};

export const createNewDevice = async (deviceInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.post('/devices', deviceInfor, config);
};

export const updateDevicePassword = (deviceId, deviceInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.put('/devices/' + deviceId + '/changepassword', deviceInfor, config);
};

export const updateDeviceZone = (deviceId, zoneInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.put('/devices/' + deviceId + '/updatezone', zoneInfor, config);
};

export const deleteDevice = (deviceId, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.delete('/devices/' + deviceId, config);
};

//Device Types

export const getDeviceTypes = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('/devices/types', config);
};

//Reports

export const geReportFilters = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('/reports/filters', config);
};

export const geReports = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('/reports', config);
};

export const geReportData = (reportId, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('/reports/' + reportId + '/data', config);
};

export const createNewReport = async (reportInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.post('/reports', reportInfor, config);
};

//Dashboard
export const geDashboardData = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('/dashboard/', config);
};

//States
export const geStates = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('/states/', config);
};

//Zones
export const getZones = token => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.get('zones', config);
};

export const createNewZone = (locationInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.post('zones', locationInfor, config);
};

export const updateZone = (zoneId, zoneInfor, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.put('zones/' + zoneId, zoneInfor, config);
};

export const deleteZone = (zoneId, token) => {
  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    }
  };

  return API.delete('zones/' + zoneId, config);
};

//Inventroy
export const inventorySearch = (data, page, limit, token) => {
  let params = {
    category_ids: [data.category_ids],
    location_ids: [data.location_ids],
    status_ids: [data.status_ids],
    page,
    limit
  };

  if (data.added_date_range !== null) {
    params = {
      ...params,
      added_date_range: [data.added_date_range]
    };
  }

  if (data.last_access_date_range !== null) {
    params = {
      ...params,
      added_date_range: [data.added_date_range]
    };
  }

  let config = {
    headers: {
      authorization: token === null ? localStorage.getItem('token') : token,
      'x-ssg-tenant-id': localStorage.getItem('slug')
    },
    params: params
  };

  return API.get('/inventory', config);
};
