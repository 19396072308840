import {
  CUSTOMER_CONTAINER_STATUS_START,
  CCUSTOMER_CONTAINER_STATUS_FAIL,
  CUSTOMER_CONTAINER_STATUS_SUCCESS
} from '../types/types';
import { getContainerStatus } from '../../../services/customer/customer';

export const containerStatusStart = () => {
  return {
    type: CUSTOMER_CONTAINER_STATUS_START,
    payload: { error: null }
  };
};

export const containerStatusSuccess = status => {
  return {
    type: CUSTOMER_CONTAINER_STATUS_SUCCESS,
    payload: status
  };
};

export const containerStatusFail = error => {
  return {
    type: CCUSTOMER_CONTAINER_STATUS_FAIL,
    payload: { error }
  };
};

export const containerStatus = token => {
  return dispatch => {
    dispatch(containerStatusStart());

    getContainerStatus(token)
      .then(response => {
        dispatch(containerStatusSuccess(response.data));
      })
      .catch(err => {
        try {
          dispatch(containerStatusFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(containerStatusFail('Please try again later..'));
        }
      });
  };
};
