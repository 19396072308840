import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Card, CardBody, Alert } from 'reactstrap';
import CardSummary from './CardSummary';
import PlanSummary from './PlanSummary';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { geDashboardData } from '../../../services/customer/customer';
import Loader from '../../../components/common/Loader';

import { isIterableArray } from '../../../helpers/utils';
import AssetsTable from './AssetsTable';
import RecentAccesAssets from './RecentAccesAssets';

const Dashboard = () => {
  const token = useSelector(state => state.Customer.customerToken);

  // State
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState([]);
  const [totalassets, settotalassets] = useState(0);
  const [totalcategories, settotalcategories] = useState(0);
  const [totalcontainers, settotalcontainers] = useState(0);
  const [plansummery, setplansummery] = useState(null);
  const [planname, setplanname] = useState(null);
  const [recentlyaddedassets, setrecentlyaddedassets] = useState([]);
  const [recentlyaccessassets, setrecentlyaccessassets] = useState([]);

  useEffect(() => {
    seterror([]);
    setloading(true);

    geDashboardData(token)
      .then(response => {
        settotalassets(response.data.assets);
        settotalcategories(response.data.categories);
        settotalcontainers(response.data.containers);
        setplansummery(response.data.available_settings);
        setplanname(response.data.available_settings.plan.name);
        setrecentlyaddedassets(response.data.recently_added_assets);
        setrecentlyaccessassets(response.data.recently_access_assets);
      })
      .catch(err => {
        try {
          setloading(false);
          err.response.data.errors.forEach(er => {
            seterror(prevArray => [...prevArray, er.message]);
          });
        } catch (ex) {
          setloading(false);
          seterror(['Please try again later...']);
        }
      });

    setTimeout(function() {
      setloading(false);
    }, 2000);
  }, []);

  return (
    <Fragment>
      {loading === true ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="card-deck">
            <CardSummary title="Total Assets" color="warning" linkText="See all">
              {totalassets}
            </CardSummary>
            <CardSummary title="Total Categories" color="info" linkText="All orders">
              {totalcategories}
            </CardSummary>
            <CardSummary title="Total Containers" color="success" linkText="Statistics">
              {totalcontainers}
            </CardSummary>
            {planname !== null && (
              <PlanSummary
                title={planname}
                handHelds={plansummery !== null && plansummery['Handheld Devices']}
                users={plansummery !== null && plansummery['User Accounts']}
                reports={plansummery !== null && plansummery['Reports']}
                readers={plansummery !== null && plansummery['Fixed Readers']}
              />
            )}
          </div>

          {isIterableArray(recentlyaddedassets) ? (
            <Card className="mb-3">
              <FalconCardHeader title="Recently Added Assets" light={false} />
              <CardBody className="p-0">
                <AssetsTable data={recentlyaddedassets} />
              </CardBody>
            </Card>
          ) : (
            <Row className="p-card">
              <Col>
                <Alert color="info" className="mb-0">
                  No Recently Added Assets found!
                </Alert>
              </Col>
            </Row>
          )}

          {isIterableArray(recentlyaccessassets) ? (
            <Card className="mb-3">
              <FalconCardHeader title="Recently Accessed Assets" light={false} />
              <CardBody className="p-0">
                <RecentAccesAssets data={recentlyaccessassets} />
              </CardBody>
            </Card>
          ) : (
            <Row className="p-card">
              <Col>
                <Alert color="info" className="mb-0">
                  No Recently Added Assets found!
                </Alert>
              </Col>
            </Row>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Dashboard;
