import * as actionTypes from '../../actions/ssg/types/types';
import { updateObject } from '../../reducers/utility/Utility';

const initialState = {
  /*Auth */
  token: null,
  refreshToken: null,
  userId: null,
  userType: null,
  authError: null,
  authLoading: false,

  confirmMail: null,

  statusLoading: false,
  status: null,
  statusError: null,

  rolesLoading: false,
  roles: null,
  rolesError: null,

  plansLoading: false,
  plans: null,
  plansError: null,

  settingsLoading: false,
  settings: null,
  settingsError: null,

  countriesLoading: false,
  countries: null,
  countriesError: null,

  statesLoading: false,
  states: null,
  statesError: null,

  timeZoneLoading: false,
  timeZones: null,
  timeZoneError: null
};

/*Auth */
const authStart = (state, action) => {
  return updateObject(state, { authError: null, authLoading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.payload.token,
    refreshToken: action.payload.refreshToken,
    userId: action.payload.userId,
    userType: action.payload.userType,
    authError: null,
    authLoading: false
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    authError: action.payload.error,
    authLoading: false
  });
};

const authOut = (state, action) => {
  return updateObject(state, {
    token: null,
    userId: null,
    userType: null,
    authError: null,
    authLoading: false
  });
};

const storConfirmMail = (state, action) => {
  return updateObject(state, {
    confirmMail: action.payload.email
  });
};

const statusStart = (state, action) => {
  return updateObject(state, { statusError: null, statusLoading: true });
};

const statusSuccess = (state, action) => {
  return updateObject(state, { status: action.payload, statusLoading: false });
};

const statusFail = (state, action) => {
  return updateObject(state, {
    statusError: action.payload.error,
    statusLoading: false
  });
};

const rolsStart = (state, action) => {
  return updateObject(state, { rolesError: null, rolesLoading: true });
};

const rolsSuccess = (state, action) => {
  return updateObject(state, { roles: action.payload, rolesLoading: false });
};

const rolsFail = (state, action) => {
  return updateObject(state, {
    rolesError: action.payload.error,
    rolesLoading: false
  });
};

const plansStart = (state, action) => {
  return updateObject(state, { plansError: null, plansLoading: true });
};

const plansSuccess = (state, action) => {
  return updateObject(state, { plans: action.payload, plansLoading: false });
};

const plansFail = (state, action) => {
  return updateObject(state, {
    plansError: action.payload.error,
    plansLoading: false
  });
};

const settingsStart = (state, action) => {
  return updateObject(state, { settingsError: null, settingsLoading: true });
};

const settingsSuccess = (state, action) => {
  return updateObject(state, { settings: action.payload, settingsLoading: false });
};

const settingsFail = (state, action) => {
  return updateObject(state, {
    settingsError: action.payload.error,
    settingsLoading: false
  });
};

const countriesStart = (state, action) => {
  return updateObject(state, { countriesError: null, countriesLoading: true });
};

const countriesSuccess = (state, action) => {
  return updateObject(state, { countries: action.payload, countriesLoading: false });
};

const countriesFail = (state, action) => {
  return updateObject(state, {
    countriesError: action.payload.error,
    countriesLoading: false
  });
};

const statesStart = (state, action) => {
  return updateObject(state, { statesError: null, statesLoading: true });
};

const statesSuccess = (state, action) => {
  return updateObject(state, { states: action.payload, statesLoading: false });
};

const statesFail = (state, action) => {
  return updateObject(state, {
    statesError: action.payload.error,
    statesLoading: false
  });
};

const timeZoneStart = (state, action) => {
  return updateObject(state, { timeZoneError: null, timeZoneLoading: true });
};

const timeZoneSuccess = (state, action) => {
  return updateObject(state, { timeZones: action.payload, timeZoneLoading: false });
};

const timeZoneFail = (state, action) => {
  return updateObject(state, {
    timeZoneError: action.payload.error,
    timeZoneLoading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SSG_AUTH_START:
      return authStart(state, action);

    case actionTypes.SSG_AUTH_SUCCESS:
      return authSuccess(state, action);

    case actionTypes.SSG_AUTH_FAIL:
      return authFail(state, action);

    case actionTypes.SSG_AUTH_OUT:
      return authOut(state, action);

    case actionTypes.SSG_CONFIRM_MAIL:
      return storConfirmMail(state, action);

    case actionTypes.SSG_STATUS_START:
      return statusStart(state, action);

    case actionTypes.SSG_STATUS_SUCCESS:
      return statusSuccess(state, action);

    case actionTypes.SSG_STATUS_FAIL:
      return statusFail(state, action);

    case actionTypes.SSG_ROLE_START:
      return rolsStart(state, action);

    case actionTypes.SSG_ROLE_SUCCESS:
      return rolsSuccess(state, action);

    case actionTypes.SSG_ROLE_FAIL:
      return rolsFail(state, action);

    case actionTypes.SSG_PLAN_START:
      return plansStart(state, action);

    case actionTypes.SSG_PLAN_SUCCESS:
      return plansSuccess(state, action);

    case actionTypes.SSG_PLAN_FAIL:
      return plansFail(state, action);

    case actionTypes.SSG_SETTINGS_START:
      return settingsStart(state, action);

    case actionTypes.SSG_SETTINGS_SUCCESS:
      return settingsSuccess(state, action);

    case actionTypes.SSG_SETTINGS_FAIL:
      return settingsFail(state, action);

    case actionTypes.SSG_COUNTRIES_START:
      return countriesStart(state, action);

    case actionTypes.SSG_COUNTRIES_SUCCESS:
      return countriesSuccess(state, action);

    case actionTypes.SSG_COUNTRIES_FAIL:
      return countriesFail(state, action);

    case actionTypes.SSG_STATES_START:
      return statesStart(state, action);

    case actionTypes.SSG_STATES_SUCCESS:
      return statesSuccess(state, action);

    case actionTypes.SSG_STATES_FAIL:
      return statesFail(state, action);

    case actionTypes.SSG_TIMEZONE_START:
      return timeZoneStart(state, action);

    case actionTypes.SSG_TIMEZONE_SUCCESS:
      return timeZoneSuccess(state, action);

    case actionTypes.SSG_TIMEZONE_FAIL:
      return timeZoneFail(state, action);

    default:
      return state;
  }
};

export default reducer;
