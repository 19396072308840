import {
  CUSTOMER_CATEGORY_STATUS_START,
  CCUSTOMER_CATEGORY_STATUS_FAIL,
  CUSTOMER_CATEGORY_STATUS_SUCCESS
} from '../types/types';
import { getCategoryStatus } from '../../../services/customer/customer';

export const categoryStatusStart = () => {
  return {
    type: CUSTOMER_CATEGORY_STATUS_START,
    payload: { error: null }
  };
};

export const categoryStatusSuccess = status => {
  return {
    type: CUSTOMER_CATEGORY_STATUS_SUCCESS,
    payload: status
  };
};

export const categoryStatusFail = error => {
  return {
    type: CCUSTOMER_CATEGORY_STATUS_FAIL,
    payload: { error }
  };
};

export const categoryStatus = token => {
  return dispatch => {
    dispatch(categoryStatusStart());

    getCategoryStatus(token)
      .then(response => {
        dispatch(categoryStatusSuccess(response.data));
      })
      .catch(err => {
        try {
          dispatch(categoryStatusFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(categoryStatusFail('Please try again later..'));
        }
      });
  };
};
