import { CUSTOMER_CONTAINER_START, CUSTOMER_CONTAINER_SUCCESS, CUSTOMER_CONTAINER_FAIL } from '../types/types';
import { getContainers } from '../../../services/customer/customer';

export const containerStart = () => {
  return {
    type: CUSTOMER_CONTAINER_START,
    payload: { error: null }
  };
};

export const containerSuccess = containers => {
  return {
    type: CUSTOMER_CONTAINER_SUCCESS,
    payload: containers
  };
};

export const containerFail = error => {
  return {
    type: CUSTOMER_CONTAINER_FAIL,
    payload: { error }
  };
};

export const containers = token => {
  return dispatch => {
    dispatch(containerStart());
    //  TODO change Values
    getContainers(1, 10, token)
      .then(response => {
        dispatch(containerSuccess(response.data.records));
      })
      .catch(err => {
        console.log(err.reponse);
        try {
          dispatch(containerFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(containerFail('Please try again later..'));
        }
      });
  };
};
