import React from 'react';
import { Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import Flex from '../../../components/common/Flex';
import Dot from '../../../components/common/Dot';

const TenantByPlanItem = ({ color, name, value }) => (
  <Flex justify="between" align="center" className="mb-1">
    <Flex align="center">
      <Dot style={{ backgroundColor: color }} />
      <span className="font-weight-semi-bold">{name}</span>
      <Badge pill className="ml-2 bg-200 text-primary">
        {value}
      </Badge>
    </Flex>
  </Flex>
);

TenantByPlanItem.propsType = {
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
};

export default TenantByPlanItem;
