import { SSG_COUNTRIES_START, SSG_COUNTRIES_SUCCESS, SSG_COUNTRIES_FAIL } from '../types/types';
import { getCountries } from '../../../services/ssg/ssg';

export const countriesStart = () => {
  return {
    type: SSG_COUNTRIES_START,
    payload: { error: null }
  };
};

export const countriesSuccess = status => {
  return {
    type: SSG_COUNTRIES_SUCCESS,
    payload: status
  };
};

export const countriesFail = error => {
  return {
    type: SSG_COUNTRIES_FAIL,
    payload: { error }
  };
};

export const countries = token => {
  return dispatch => {
    dispatch(countriesStart());

    getCountries(token)
      .then(response => {
        dispatch(countriesSuccess(response.data));
      })
      .catch(err => {
        try {
          dispatch(countriesFail(err.response.data.errors[0].message));
        } catch (ex) {
          dispatch(countriesFail('Please try again later..'));
        }
      });
  };
};
